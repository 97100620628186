import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import "../../styles.css";
import { Checkout } from "./Checkout";
import { useAuth } from "../../Contexts/AuthContext";

function ServiceModal(props) {
  console.log(props);
  const { currentUser } = useAuth();
  const [showBuy, setShowBuy] = useState(false);

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.handleClose();
        setShowBuy(false);
      }}
      className="services-modal modal fade"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <CloseButton
          onClick={() => {
            props.handleClose();
            setShowBuy(false);
          }}
        />
      </Modal.Header>

      <Modal.Body>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <div className="modal-body">
                <h2 className="item-title ext-uppercase">
                  {props.service.name}
                </h2>
                <p className="item-intro text-muted">
                  Lorem ipsum dolor sit amet consectetur.
                </p>
                {props.service.imgSrc && (
                  <img
                    className="img-fluid d-block mx-auto mb-3"
                    src={
                      require(`../../assets/images/services/${props.service.imgSrc}`)
                        .default
                    }
                    alt="image"
                  />
                )}
                <div className="item-description">
                  <p>{props.service.description}</p>
                  <p>{props.service.descriptionMiddlePhrase}</p>
                  <ul className="list-inline ">
                    {props.service.descriptionArray?.map((data, key) => {
                      return <li>{data.item}</li>;
                    })}
                  </ul>

                  {showBuy && (
                    <Checkout
                      service={props.service}
                      handleClose={() => props.handleClose()}
                    />
                  )}
                  {!showBuy && (
                    <button
                      className="btn btn-primary btn-xl text-uppercase"
                      type="button"
                      onClick={() => {
                        setShowBuy(true);
                      }}
                      disabled={currentUser === null}
                    >
                      <i className="far fa-credit-card" />{" "}
                      {currentUser !== null
                        ? "Cumpara"
                        : "Logheteaza-te pentru a putea cumpara"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ServiceModal;
