import React, { useEffect, useState } from "react";
import "../../styles.css";
import { useAuth } from "../../Contexts/AuthContext";
import { db } from "../../Auth/firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { TabelStore } from "../../Components/TableStore/tableStore";
import { Button } from "react-bootstrap";

export default function HistoryTableManager() {
  const [showAdminHistoryTable, setShowAdminHistoryTable] = useState(false);
  const [showMedicHistoryTable, setShowMedicHistoryTable] = useState(false);

  const { currentUser, adminRights, medicRights } = useAuth();
  const [historyRequest, setHistoryRequest] = useState([]);
  const [medicHistoryRequest, setMedicHistoryRequest] = useState([]);

  useEffect(() => {
    onSnapshot(collection(db, "history"), (snapshot) =>
      setHistoryRequest(
        snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      )
    );
  }, []);

  useEffect(() => {
    const q = query(
      collection(db, "history"),
      where("Email", "==", `${currentUser.email}`)
    );
    onSnapshot(q, (snapshot) => {
      setMedicHistoryRequest(
        snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    });
  }, []);

  return (
    <>
      <section id="dashboard1" className="main-wrapper dashboard-fixed">
        <div className="dashboardContainer">
          <div className="switch-buttons">
            <Button
              className={`tabel-button ${
                showAdminHistoryTable ? "tabel-button-active" : ""
              }`}
              disabled={!adminRights.hasRights}
              onClick={() => {
                setShowAdminHistoryTable(true);
                setShowMedicHistoryTable(false);
              }}
            >
              {showAdminHistoryTable && (
                <i className="fas fa-thumbtack black-icon"></i>
              )}
              SWITCH TO ADMIN HISTORY
            </Button>

            <Button
              className={`tabel-button ${
                showMedicHistoryTable ? "tabel-button-active" : ""
              }`}
              disabled={!adminRights.hasRights}
              onClick={() => {
                setShowAdminHistoryTable(false);
                setShowMedicHistoryTable(true);
              }}
            >
              {showMedicHistoryTable && (
                <i className="fas fa-thumbtack black-icon"></i>
              )}
              SWITCH TO MEDIC HISTORY
            </Button>
          </div>

          {/* Show entire history for every patient and medic */}
          {showAdminHistoryTable && adminRights.hasRights && (
            <TabelStore content={historyRequest} table={"historyRequest"} />
          )}
          {/* Show history related to the logged-in medic */}
          {showMedicHistoryTable && medicRights.hasRights && (
            <TabelStore
              content={medicHistoryRequest}
              table={"historyRequest"}
            />
          )}
        </div>
      </section>
    </>
  );
}
