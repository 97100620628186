import { React, useState } from "react";
import "../../styles.css";
import "./contact.css";
import { Form, Button, FloatingLabel, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";

function Contact() {
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  const schema = yup.object().shape({
    lastName: yup
      .string()
      .required()
      .matches(/^[aA-zZ\s]+$/, {
        message: "Adaugati un nume valid",
        excludeEmptyString: true,
      }),
    firstName: yup
      .string()
      .required()
      .matches(/^[aA-zZ\s]+$/, {
        message: "Adaugati un prenume valid",
        excludeEmptyString: true,
      }),
    email: yup.string().email("Adaugati o adresa de email valida").required(),
    tel: yup.string().matches(/^[0][7]\d{8}$/, {
      message: "Adaugati un numar de telefon valid (07xxxxxxxx)",
      excludeEmptyString: true,
    }),
    message: yup
      .string()
      .min(15, "Adaugati cel putin 15 caractere")
      .max(500, "Nu adaugati mai mult de 500 de caractere")
      .required("Adaugati un mesaj"),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    console.log(values);
    setSubmitting(true);
    fetch(`${process.env.REACT_APP_BASE_SERVER_URL}/contact`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    }).then(function (response) {
      console.log("in func resp", response);
      if (response.status === 200) {
        alert("Message Sent.");
        resetForm();
      } else {
        alert("Message failed to send.");
      }
    });
  };

  return (
    <section className="section" id="contact">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Contacteaza-ne</h2>
          <h3 className="section-subheading text-muted">
            Suntem aici pentru orice intrebare...
          </h3>
        </div>
        <Formik
          validationSchema={schema}
          validateOnChange={validateAfterSubmit}
          initialValues={{
            lastName: "",
            firstName: "",
            email: "",
            tel: "",
            message: "",
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSubmit(values, { setSubmitting, resetForm });
          }}
        >
          {({
            handleSubmit,
            handleChange,
            resetForm,
            isSubmitting,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                setValidateAfterSubmit(true);
                handleSubmit();
              }}
              className="text-center"
              autoComplete="off"
              method=""
            >
              <Row className="mb-3">
                <Col>
                  <Row className="mb-3">
                    <Col>
                      <FloatingLabel controlId="inputLastName" label="Nume">
                        <Form.Control
                          type="text"
                          name="lastName"
                          placeholder="Nume"
                          value={values.lastName}
                          onChange={handleChange}
                          isValid={touched.lastName && !errors.lastName}
                          isInvalid={!!errors.lastName}
                        />
                        <Form.Control.Feedback
                          className="d-block"
                          type="invalid"
                        >
                          {errors.lastName}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                    <Col>
                      <FloatingLabel controlId="inputFirstName" label="Prenume">
                        <Form.Control
                          type="text"
                          name="firstName"
                          placeholder="Prenume"
                          value={values.firstName}
                          onChange={handleChange}
                          isValid={touched.firstName && !errors.firstName}
                          isInvalid={!!errors.firstName}
                        />
                        <Form.Control.Feedback
                          className="d-block"
                          type="invalid"
                        >
                          {errors.firstName}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Col>
                  </Row>

                  <FloatingLabel
                    controlId="inputEmail"
                    label="Email"
                    className="mb-3"
                  >
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="nume@exemplu.com"
                      value={values.email}
                      onChange={handleChange}
                      isValid={touched.email && !errors.email}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback className="d-block" type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </FloatingLabel>

                  <FloatingLabel
                    controlId="inputTelNo"
                    label="Numar de telefon (optional)"
                  >
                    <Form.Control
                      type="text"
                      name="tel"
                      placeholder="Telefon"
                      value={values.tel}
                      onChange={handleChange}
                      isValid={touched.tel && !errors.tel}
                      isInvalid={!!errors.tel}
                    />{" "}
                    <Form.Control.Feedback className="d-block" type="invalid">
                      {errors.tel}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>

                <Col>
                  <FloatingLabel
                    style={{ height: "100%" }}
                    controlId="floatingTextarea2"
                    label="Mesaj"
                  >
                    <Form.Control
                      name="message"
                      style={{ height: "100%" }}
                      as="textarea"
                      placeholder="Lasa un mesaj aici"
                      value={values.message}
                      onChange={handleChange}
                      isValid={touched.message && !errors.message}
                      isInvalid={!!errors.message}
                    />
                    <Form.Control.Feedback className="d-block" type="invalid">
                      {errors.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>

              <Button
                className="btn btn-primary btn-xl text-uppercase"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Se trimite" : "Trimite"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
}

export default Contact;
