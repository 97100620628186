import { React, useState, useRef } from "react";
import "../../styles.css";
import { Form, Button, InputGroup, FormControl, Alert } from "react-bootstrap";
import { useAuth } from "../../Contexts/AuthContext";
import { Link } from "react-router-dom";

export default function ForgotPassword() {

  const emailRef = useRef();
  const passRef = useRef();
  const { resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    setSuccess(false);

    try {
      setError("");
      setLoading(true);
      setMessage("");
      await resetPassword(emailRef.current.value, passRef);
      setSuccess(true);
      setMessage("Verificati emailul pentru a reseta parola");
    } catch (e) {
      setSuccess(false);
      setError("Resetearea parolei nu a reusit");
    }

    setLoading(false);
  }

  return (
    <section className="main-wrapper">
      <div className="emailAndPassContainer">
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{message}</Alert>}
        <Form className="mb-3" onSubmit={handleSubmit}>
          <InputGroup className="mb-3" size="lg" controlid="email">
            <InputGroup.Text>Email : </InputGroup.Text>
            <FormControl autoFocus type="email" ref={emailRef} required />
          </InputGroup>
          <Button disabled={loading} block size="lg" type="submit">
            Reseteaza parola
          </Button>
        </Form>

        <Link className="mb-3" to="/login">
          Login
        </Link>
        <div className="sign-up">
          Nu ai un cont? <Link to="/sign-up">Inregistreaza-te</Link>
        </div>
      </div>
    </section>
  );
}
