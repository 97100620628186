import React from "react";
import "@stripe/stripe-js";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import NavBar from "./Components/NavBar/NavBar";
import "./styles.css";
import "./main.scss";
import Main from "./Pages/Main/main";
import Login from "./Pages/Login/login";
import { SignUp } from "./Pages/SignUp/signUp";
import { SignUpSpecialist } from "./Pages/SignUp/signUpSpecialist";
import ForgotPassword from "./Pages/ForgotPassword/forgotPassword";
//import NotFound from "./Pages/NotFound/notFound"
import { AuthProvider, useAuth } from "./Contexts/AuthContext";
import Dashboard from "./Pages/Dashboard/dashboard";
import Services from "./Components/Services/Services";
import History from "./Pages/History/history";
import TableManager from "./Components/TableManager/tableManager";
import HistoryTableManager from "./Components/TableManager/historyTableManager";
import Success from "./Pages/Generics/Success";
import VerifyEmail from "./Pages/Generics/VerifyEmail";
import Cancel from "./Pages/Generics/Cancel";
import Not_Found from "./Pages/Generics/Not_found";
import DocViewerWrapper from "./Pages/Generics/DocViewerWrapper";

function RequireAuth({ children, redirectTo }) {
  const { currentUser } = useAuth();
  if (currentUser === null) {
    return <Navigate to={redirectTo} />;
  } else {
    if (currentUser.emailVerified === true) {
      console.log("Emailu e verificat", currentUser.emailVerified);
      return children;
    } //false, unverified email
    else {
      console.log("Emailu NU e verificat", currentUser.emailVerified);
      return <Navigate to="/verify-email" />;
    }
  }
}

function RestrictUser({ children, redirectTo }) {
  const { currentUser } = useAuth();
  if (currentUser === null) {
    return children;
  } else {
    return <Navigate to={redirectTo} />;
  }
}

function RequireSpecialRights({ children, redirectTo }) {
  const { adminRights, medicRights } = useAuth();
  if (adminRights.hasRights || medicRights.hasRights) {
    return children;
  } else {
    return <Navigate to={redirectTo} />;
  }
}

function App() {
  const isNavBarVisible = !window.location.pathname.startsWith("/view/");

  return (
    <>
      <AuthProvider>
        <BrowserRouter>
          {isNavBarVisible && <NavBar />}
          <Routes>
            <Route path="/" element={<Main />} />
            <Route
              path="/login"
              element={
                <RestrictUser redirectTo="/dashboard">
                  <Login />
                </RestrictUser>
              }
            />
            <Route
              path="/sign-up"
              element={
                <RestrictUser redirectTo="/dashboard">
                  <SignUp />
                </RestrictUser>
              }
            />
            <Route
              path="/sign-up/specialist"
              element={
                <RestrictUser redirectTo="/dashboard">
                  <SignUpSpecialist />
                </RestrictUser>
              }
            />
            <Route
              path="/dashboard/services"
              element={
                <RequireAuth redirectTo="/login">
                  <Services userPerspective={true} />
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/history"
              element={
                <RequireAuth redirectTo="/login">
                  <History userPerspective={true} />
                </RequireAuth>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <RestrictUser redirectTo="/dashboard">
                  <ForgotPassword />
                </RestrictUser>
              }
            />
            <Route
              path="/dashboard"
              element={
                <RequireAuth redirectTo="/login">
                  <Dashboard />
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard/tableManager"
              element={
                <RequireSpecialRights redirectTo="/dashboard">
                  <TableManager />
                </RequireSpecialRights>
              }
            />
            <Route
              path="/history/tableManager"
              element={
                <RequireSpecialRights redirectTo="/dashboard">
                  <HistoryTableManager />
                </RequireSpecialRights>
              }
            />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/success/:DisplayMessage" element={<Success />} />
            <Route
              path="/cancel"
              element={
                <RequireAuth redirectTo="/login">
                  <Cancel />
                </RequireAuth>
              }
            />
            <Route path="/view/:DocumentName" element={<DocViewerWrapper />} />
            <Route path="*" element={<Not_Found />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </>
  );
}

export default App;
