import React from "react";
import "./footer.css";
import logo from "../../assets/images/logo_volli.png";
import anpc1 from "../../assets/images/anpc1.png";
import anpc2 from "../../assets/images/anpc2.png";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagramSquare,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";

function onClickCookies() {
  Cookies.remove("cookieConsent");
}

const Footer = () => {
  return (
    <React.Fragment>
      <hr className="green-line" />
      <footer className="footer">
        <div className="footer-columns">
          <div className="footer-column">
            <span className="bold-text">Volli</span>
            <a href={`/#presentation`}>Ce găsesc aici?</a>
            <a href={`/#services`}>Servicii</a>
            <a href={`/#faq`}>Intrebări frecvente</a>
            <a href={`/#specialist`}>Devino expert volli</a>
            <a href={`/#contact`}>Contact</a>
          </div>
          <div className="footer-column">
            <span className="bold-text">Legal</span>
            <a href={`/view/T&C.pdf`}>Termeni si conditii</a>
            <a href={"#"} onClick={onClickCookies}>
              Cookies
            </a>
            <a href="https://anpc.ro/ce-este-sal/" target="_blank">
              <img src={anpc1} width="200" />
            </a>
            <a href="https://ec.europa.eu/consumers/odr" target="_blank">
              <img src={anpc2} width="200" />
            </a>
          </div>
          <div className="footer-column">
            <span className="bold-text">Social Media</span>
            <a href="https://twitter.com" className={"social-link"}>
              <FontAwesomeIcon
                icon={faInstagramSquare}
                className="social-icon"
              />{" "}
              Twitter
            </a>
            <a href="https://facebook.com" className={"social-link"}>
              <FontAwesomeIcon
                icon={faFacebookSquare}
                className="social-icon"
              />{" "}
              Facebook
            </a>
          </div>
        </div>
        <hr className="line" />
        <div className="copyright-section">
          <div className="logo">
            <img src={logo} alt="Volli" className="logo-image" />
          </div>
          <div className="copyright">&copy; 2023 Volli</div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
