import React, { useEffect, useState, useMemo } from "react";
import { Button } from "react-bootstrap";
import { useTable, useSortBy, useFlexLayout, useRowSelect } from "react-table";
import "./tableStore.css";
import BTable from "react-bootstrap/Table";

export function BootstrapUserTable(props) {
  const data = useMemo(() => [...props.content], [props.content]);

  function downloadInvoice(id) {
    console.log(id);
  }
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 400,
    }),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },

      {
        Header: "Email",
        accessor: "Email",
        width: 150,
      },
      {
        Header: "Pachet",
        accessor: "Pachet",
        width: 70,
      },
      {
        Header: "Observatii",
        accessor: "Observatii",
        width: 200,
      },
      {
        Header: "Medic asignat",
        accessor: "Medic asignat",
      },
      {
        Header: "Status",
        accessor: "Status",
        width: 70,
      },
      {
        Header: "Factura",
        accessor: "Factura",
        width: 70,
        Cell: ({ row }) => (
          <Button
            onClick={() => {
              downloadInvoice(row.values.id);
            }}
          >
            ⬆️
          </Button>
        ),
      },
    ],
    []
  );
  const tableInstance = useTable(
    { columns, data, defaultColumn },
    useSortBy,
    useFlexLayout,
    useRowSelect
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = tableInstance;

  const isEven = (idx) => idx % 2 === 0;

  return (
    <>
      <BTable striped bordered hover size="sm" {...getTableProps()}>
        <thead className="table-head">
          {headerGroups.map((headerGroup) => (
            <tr className="table-row" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className="table-header" {...column.getHeaderProps()}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    {column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="table-body" {...getTableBodyProps()}>
          {rows.map((row, idx) => {
            prepareRow(row);
            return (
              <tr
                className={isEven(idx) ? "table-row row-is-even" : "table-row"}
                {...row.getRowProps()}
              >
                {row.cells.map((cell, idx) => (
                  <td
                    className="table-data"
                    {...cell.getCellProps({
                      style: {
                        minWidth: cell.column.minWidth,
                        maxWidth: cell.column.maxWidth,
                      },
                    })}
                  >
                    {cell.render("Cell")}{" "}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </BTable>
    </>
  );
}
