import "../../styles.css";

const Not_Found = () => {
  return (
    <section id="dashboard1" className="main-wrapper dashboard-fixed">
      <h1>404 - Pagina nu a fost gasita!</h1>
    </section>
  );
};

export default Not_Found;
