export const Asignat = "Asignat";
export const Reasignat = "Reasignat";
export const InCurs = "In curs";
export const Rejectat = "Rejectat";
export const Solutionat = "Solutionat";

export const CITIES = {
    TIMISOARA : 'Timișoara',
    CLUJ : 'Cluj'
}
