import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import {
  Button,
  CloseButton,
  Modal,
  Form,
  FloatingLabel,
} from "react-bootstrap";
import { UpdateStatusField } from "./ModalLogic";
import { Formik } from "formik";
import * as yup from "yup";
import * as global from "../../Helpers/globalConsts";

function StatusModal(props) {
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  console.log("StatusModal props: ", props);

  const handleSubmit = async (values, {setSubmitting}) => {
    if (
        await UpdateStatusField(
            values.Status,
            props.request.Status,
            values.ObservatiiMedic,
            props.request.id
        )
    ) {
      props.handleClose();
    } else {
      alert("Statusul nu a putut fi modificat!");
    }
    setSubmitting(false);
  };

  const schema = yup.object().shape({
    Status: yup.string().required(),
    ObservatiiMedic: yup
        .string()
        .when("Status", {
          is: (Status) =>
              Status === global.Rejectat || Status === global.Solutionat,
          then: yup
              .string()
              .test(
                  "minLength",
                  "ObservatiiMedic must be at least 15 characters long",
                  (value) => value && value.length >= 15
              ),
        }),
  });

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      className="services-modal modal fade"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <CloseButton onClick={props.handleClose} />
      </Modal.Header>

      <Modal.Body>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <div className="modal-body">
                <h2 className="item-title ext-uppercase">Update status</h2>

                <Formik
                  validationSchema={schema}
                  validateOnChange={validateAfterSubmit}
                  onSubmit={(values, { setSubmitting }) => {
                    console.log("subm:", { setSubmitting });
                    handleSubmit(values, { setSubmitting });
                  }}
                  initialValues={{
                    Id: props.request.id,
                    Email: props.request.Email,
                    Nume: props.request.NumeClient,
                    Prenume: props.request.PrenumeClient,
                    Pachet: props.request.Pachet,
                    Observatii: props.request.Observatii,
                    Status: "",
                    ObservatiiMedic: "",
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    isSubmitting,
                    touched,
                  }) => (
                    <Form
                      noValidate
                      onSubmit={(e) => {
                        e.preventDefault();
                        setValidateAfterSubmit(true);
                        handleSubmit();
                      }}
                    >
                      <FloatingLabel key="id" label="Id" className="mb-3">
                        <Form.Control
                          type="text"
                          name="id"
                          value={values.Id}
                          readOnly
                        />
                      </FloatingLabel>

                      <FloatingLabel key="Email" label="Email" className="mb-3">
                        <Form.Control
                          type="text"
                          name="Email"
                          value={values.Email}
                          readOnly
                        />
                      </FloatingLabel>

                      <FloatingLabel key="Nume" label="Nume" className="mb-3">
                        <Form.Control
                          type="text"
                          name="Nume"
                          value={values.Nume}
                          readOnly
                        />
                      </FloatingLabel>

                      <FloatingLabel
                        key="Prenume"
                        label="Prenume"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="Prenume"
                          value={values.Prenume}
                          readOnly
                        />
                      </FloatingLabel>

                      <FloatingLabel
                        key="Pachet"
                        label="Pachet"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="Pachet"
                          value={values.Pachet}
                          readOnly
                        />
                      </FloatingLabel>

                      <FloatingLabel
                        key="Observatii"
                        label="Observatii"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="Observatii"
                          value={values.Observatii}
                          readOnly
                        />
                      </FloatingLabel>

                      <FloatingLabel
                        key="status-dropdown"
                        label="Status update"
                        className="mb-3"
                      >
                        <Form.Select
                          name="Status"
                          value={values.Status}
                          onChange={handleChange}
                          isValid={touched.Status && !errors.Status}
                          isInvalid={!!errors.Status}
                        >
                          <option
                            key={"form-select-" + global.Asignat}
                            value={global.Asignat}
                          >
                            {global.Asignat}
                          </option>
                          <option
                            key={"form-select-" + global.InCurs}
                            value={global.InCurs}
                          >
                            {global.InCurs}
                          </option>
                          <option
                            key={"form-select-" + global.Rejectat}
                            value={global.Rejectat}
                          >
                            {global.Rejectat}
                          </option>
                          <option
                            key={"form-select-" + global.Solutionat}
                            value={global.Solutionat}
                          >
                            {global.Solutionat}
                          </option>
                        </Form.Select>
                        <Form.Control.Feedback
                          className="d-block"
                          type="invalid"
                        >
                          {errors.Status}
                        </Form.Control.Feedback>
                      </FloatingLabel>

                      <FloatingLabel
                        key="ObservatiiMedic"
                        label="ObservatiiMedic"
                        className="mb-3"
                      >
                        {values.Status === global.Solutionat ||
                        values.Status === global.Rejectat ? (
                          <Form.Control
                            type="text"
                            name="ObservatiiMedic"
                            value={values.ObservatiiMedic}
                            onChange={handleChange}
                            isValid={
                              touched.ObservatiiMedic && !errors.ObservatiiMedic
                            }
                            isInvalid={!!errors.ObservatiiMedic}
                          />
                        ) : (
                          <Form.Control
                            type="text"
                            name="ObservatiiMedic"
                            value={(values.ObservatiiMedic = "")}
                            onChange={handleChange}
                            readOnly
                          />
                        )}
                        <Form.Control.Feedback
                          className="d-block"
                          type="invalid"
                        >
                          {errors.ObservatiiMedic}
                        </Form.Control.Feedback>
                      </FloatingLabel>

                      <Button type="submit" disabled={isSubmitting}>
                        Assign
                      </Button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default StatusModal;
