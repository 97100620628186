import { Button, Alert } from "react-bootstrap";
import "../../styles.css";
import { useState, useEffect } from "react";
import { useAuth } from "../../Contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const VerifyEmail = () => {
  const [success, setSuccess] = useState("");
  const { verifyemail, currentUser, specialistVerification } = useAuth();
  let navigate = useNavigate();

  useEffect(() => {
    if (currentUser !== null && currentUser.emailVerified === true)
      navigate("/dashboard");
    // if (currentUser === null) navigate("/login");
  }, []);

  return (
    <section id="dashboard1" className="main-wrapper dashboard-fixed">
      <h2>
        Validarea emailului este obligatorie inainte sa poti accesa
        functionalitatea completa a platformei Volli
        <Button
          onClick={() => {
            console.log("currentUser in VerifyEmail", currentUser);
            if (verifyemail()) {
              setSuccess(true);
            } else {
              setSuccess(false);
            }
          }}
        >
          Trimite email de verificare!
        </Button>
        {success && <Alert variant="danger">{success}</Alert>}
        {specialistVerification &&
          "Un reprezentant Volli va lua legatura cu dvs. prin intermeniul emailului pentru a confirma cererea Expert Volli"}
      </h2>
    </section>
  );
};

export default VerifyEmail;
