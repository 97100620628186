import "../../styles.css";
import { useParams } from "react-router-dom";

const Success = () => {
  const {DisplayMessage} = useParams()
  return (
    <section id="dashboard1" className="main-wrapper dashboard-fixed">
      <h2>
        {DisplayMessage}
      </h2>
    </section>
  );
};

export default Success;
