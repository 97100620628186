import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import {
  CloseButton,
  Modal,
  Form,
  FloatingLabel,
  Button,
} from "react-bootstrap";
import { FinaliseRequest } from "./ModalLogic";
import * as global from "../../Helpers/globalConsts";
import { Formik } from "formik";
import * as yup from "yup";

function FinaliseModal(props) {
  const [futureStatus, setFutureStatus] = useState();

  useEffect(() => {
    setFutureStatus(props.request.Status);
  }, [props.request.Status]);

  useEffect(() => {
    console.log("astabasta ", futureStatus);
  }, [futureStatus]);

  console.log("finaliseModal props: ", props);

  const handleSubmit = async () => {
    if (
      (await FinaliseRequest(
        futureStatus,
        props.request.Status,
        props.request.id,
        props.request
      )) === true
    ) {
      console.log("Intra pe ===true,");
      props.handleClose();
    } else {
      console.log("Intra pe ===false, ");
    }
  };

  const schema = yup.object().shape({
    Status: yup.string().when("StatusChangeSwitch", {
      is: (StatusChangeSwitch) => StatusChangeSwitch === true,
      then: yup.string().required("Acest camp este obligatoriu"),
      otherwise: yup.string(),
      StatusChangeSwitch: yup.string(),
    }),
  });

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      className="services-modal modal fade"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <CloseButton onClick={props.handleClose} />
      </Modal.Header>

      <Modal.Body>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <div className="modal-body">
                <h2 className="item-title ext-uppercase">Finalizare cerere</h2>

                <Formik
                  validationSchema={schema}
                  onSubmit={(values) => {
                    handleSubmit();
                  }}
                  initialValues={{
                    Id: props.request.id,
                    Email: props.request.Email,
                    Nume: props.request.NumeClient,
                    Prenume: props.request.PrenumeClient,
                    Pachet: props.request.Pachet,
                    Observatii: props.request.Observatii,
                    MedicAsignat: props.request.MedicAsignat,
                    DataAsignarii: props.request.DataAsignarii,
                    Asignator: props.request.Asignator,
                    Status: props.request.Status,
                    StatusChangeSwitch: false,
                    ObservatiiMedic: props.request.ObservatiiMedic,
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    isSubmitting,
                  }) => (
                    <Form
                      noValidate
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >
                      <FloatingLabel key="id" label="Id" className="mb-3">
                        <Form.Control
                          type="text"
                          name="id"
                          value={values.Id}
                          readOnly
                        />
                      </FloatingLabel>

                      <FloatingLabel key="Email" label="Email" className="mb-3">
                        <Form.Control
                          type="text"
                          name="Email"
                          value={values.Email}
                          readOnly
                        />
                      </FloatingLabel>

                      <FloatingLabel key="Nume" label="Nume" className="mb-3">
                        <Form.Control
                          type="text"
                          name="Nume"
                          value={values.Nume}
                          readOnly
                        />
                      </FloatingLabel>

                      <FloatingLabel
                        key="Prenume"
                        label="Prenume"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="Prenume"
                          value={values.Prenume}
                          readOnly
                        />
                      </FloatingLabel>

                      <FloatingLabel
                        key="Pachet"
                        label="Pachet"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="Pachet"
                          value={values.Pachet}
                          readOnly
                        />
                      </FloatingLabel>

                      <FloatingLabel
                        key="Observatii"
                        label="Observatii"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="Observatii"
                          value={values.Observatii}
                          readOnly
                        />
                      </FloatingLabel>

                      <FloatingLabel
                        key="status-dropdown"
                        label="Status"
                        className="mb-3"
                      >
                        <Form.Control
                          as="select"
                          placeholder="Status"
                          name="Status"
                          value={values.Status}
                          onChange={(e) => {
                            e.persist = () => {}; //error:formik e.persit is not a function -fix
                            handleChange(e);
                            console.log("Dabiiii", e.target.value);
                            setFutureStatus(e.target.value);
                          }}
                          disabled={!values.StatusChangeSwitch}
                          isInvalid={!!errors.medicSelect}
                        >
                          <option
                            key={"form-select-" + global.Reasignat}
                            value={global.Reasignat}
                          >
                            {global.Reasignat}
                          </option>
                          <option
                            key={"form-select-" + global.Rejectat}
                            value={global.Rejectat}
                          >
                            {global.Rejectat}
                          </option>
                          <option
                            key={"form-select-" + global.Solutionat}
                            value={global.Solutionat}
                          >
                            {global.Solutionat}
                          </option>
                        </Form.Control>
                      </FloatingLabel>

                      <FloatingLabel
                        key="ObservatiiMedic"
                        label="ObservatiiMedic"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="ObservatiiMedic"
                          value={values.ObservatiiMedic}
                          readOnly
                        />
                      </FloatingLabel>

                      <div>
                        <Form.Check
                          className="mb-3"
                          type="switch"
                          name="StatusChangeSwitch"
                          label="Deblocheaza"
                          value={values.StatusChangeSwitch}
                          onChange={handleChange}
                        />
                        <Button
                          type="submit"
                          disabled={!values.StatusChangeSwitch}
                        >
                          Finalizare
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default FinaliseModal;
