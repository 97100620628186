import "../../styles.css";
import "./faq.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Accordion from "react-bootstrap/Accordion";
import { faq } from "../../Helpers/serviceData";

function Faq() {
  return (
    <section className="section" id="faq">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">
            Intrebari frecvente!
          </h2>
          <h3 className="section-subheading text-muted">
            Suntem aici pentru tine!
          </h3>
        </div>
        <Accordion defaultActiveKey="0" className="faq-font">
          {faq.map((data, key) => {
            return (
              <Accordion.Item eventKey={data.id}>
                <Accordion.Header>{data.title}</Accordion.Header>
                <Accordion.Body>{data.content}</Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
    </section>
  );
}

export default Faq;
