import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import {
  CloseButton,
  Modal,
  Form,
  FloatingLabel,
  Button,
} from "react-bootstrap";
import "../../styles.css";
import { Formik } from "formik";
import * as yup from "yup";
import { assignAndMove } from "./ModalLogic";
import { useAuth } from "../../Contexts/AuthContext";

function AssignModal(props) {
  const { currentUser } = useAuth();
  console.log(props);

  const handleSubmit = (values, { setSubmitting }) => {
    assignAndMove(props.request, values.medicSelect, currentUser.email);
    //setSubmitting(false); //nu trebuie adaugat ca sa nu se poata da assign de 2 ori
    props.handleClose();
  };

  const schema = yup.object().shape({
    medicSelect: yup.string().required("Selectati un medic"),
  });

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      className="services-modal modal fade"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <CloseButton onClick={props.handleClose} />
      </Modal.Header>

      <Modal.Body>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <div className="modal-body">
                <h2 className="item-title ext-uppercase">Asignare cerere</h2>

                <Formik
                  validationSchema={schema}
                  onSubmit={(values, { setSubmitting }) => {
                    console.log("subm:", { setSubmitting });
                    handleSubmit(values, { setSubmitting });
                  }}
                  initialValues={{
                    Id: props.request.id,
                    Email: props.request.Email,
                    Nume: props.request.NumeClient,
                    Prenume: props.request.PrenumeClient,
                    Pachet: props.request.Pachet,
                    Observatii: props.request.Observatii,
                    medicSelect: "",
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    isSubmitting,
                  }) => (
                    <Form
                      noValidate
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >
                      <FloatingLabel key="id" label="Id" className="mb-3">
                        <Form.Control
                          type="text"
                          name="id"
                          value={values.Id}
                          readOnly
                        />
                      </FloatingLabel>

                      <FloatingLabel key="Email" label="Email" className="mb-3">
                        <Form.Control
                          type="text"
                          name="Email"
                          value={values.Email}
                          readOnly
                        />
                      </FloatingLabel>

                      <FloatingLabel key="Nume" label="Nume" className="mb-3">
                        <Form.Control
                          type="text"
                          name="Nume"
                          value={values.Nume}
                          readOnly
                        />
                      </FloatingLabel>

                      <FloatingLabel
                        key="Prenume"
                        label="Prenume"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="Prenume"
                          value={values.Prenume}
                          readOnly
                        />
                      </FloatingLabel>

                      <FloatingLabel
                        key="Pachet"
                        label="Pachet"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="Pachet"
                          value={values.Pachet}
                          readOnly
                        />
                      </FloatingLabel>

                      <FloatingLabel
                        key="Observatii"
                        label="Observatii"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="Observatii"
                          value={values.Observatii}
                          readOnly
                        />
                      </FloatingLabel>

                      <FloatingLabel
                        key="medic-dropdown"
                        label="Asignare medic"
                        className="mb-3"
                      >
                        <Form.Control
                          as="select"
                          placeholder="Medic"
                          name="medicSelect"
                          value={values.medicSelect}
                          onChange={handleChange}
                          isInvalid={!!errors.medicSelect}
                        >
                          <option value="invalid">Selecteaza un medic</option>
                          {props.medicUsers.map((data) => {
                            return (
                              <option
                                key={"form-select-" + data.email}
                                value={data.email}
                                label={data.email}
                              >
                                {data.Nume +
                                  " " +
                                  data.Prenume +
                                  " (" +
                                  data.email +
                                  ")"}
                              </option>
                            );
                          })}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.medicSelect}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                      <Button type="submit" disabled={isSubmitting}>
                        Assign
                      </Button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AssignModal;
