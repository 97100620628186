import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './cookieBanner.css';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const hasConsent = Cookies.get('cookieConsent');
    if (!hasConsent) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set('cookieConsent', 'true', { expires: 365 }); // Cookie expires in 1 year
    setShowBanner(false);
  };
  console.log('inside cookie');

  return (
    showBanner && (
      <div className="cookie-banner">
        <p>This website uses cookies to ensure you get the best experience on our website.</p>
        <button onClick={handleAccept}>Got it!</button>
      </div>
    )
  );
};

export default CookieBanner;
