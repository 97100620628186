export const faq = [
  {
    id: 0,
    title: "Ce este inclus în pachetele Acut și Check Up? Există costuri ascunse?",
    content: `Toate procedurile menționate sunt INCLUSE fără alte costuri adiționale.`,
  },
  {
    id: 1,
    title: "Cum ajunge medicul acasă?",
    content:
      "Un administrator Volli va lua legătura cu tine ca să stabiliți detaliile legate de vizita medicului.",
  },
  {
    id: 2,
    title: "Cât de repede ajunge medicul?",
    content: `Vizita la domiciliu este influențată de mai mulți factori externi cum ar fi : traficul, numărul de solicitări etc. Prioritatea noastră e soluționarea problemei tale în cel mai scurt timp posibil.`,
  },
  {
    id: 3,
    title: "Pot întrerupe abonamentul oricând ?",
    content: 'Da ! Abonamentul se poate întrerupe oricând, din panoul de control al utilizatorului. Nu uita însă că numărul abonamentelor este limitat.',
  },
];

export const serviceData = [
  {
    id: 1,
    name: "Pachet ACUT",
    catchPhrase: "...",
    description:
      "Este pachetul de care ai nevoie atunci când tu sau un membru al familiei tale aveți o problemă acută (exemple: infecții respiratorii, probleme gastro-intestinale, febră, infecții de tract urinar etc). Acesta include:",
    descriptionArray: [
      {
        id: 1,
        item: "Evaluarea stării de sanatate",
      },
      {
        id: 2,
        item: "Măsurarea parametrilor vitali (Tensiune arteriala, frecvența respiratorie, frecvența",
      },
      {
        id: 3,
        item: "Electrocardiograma (EKG) - unde e cazul",
      },
      {
        id: 4,
        item: "Teste rapide - unde e cazul",
      },
      {
        id: 5,
        item: "Auscultație: Cardiacă si Pulmonara",
      },
      {
        id: 6,
        item: "Recomandare/Plan de tratamente",
      },
    ],
    price: {
      stripeId: "price_1OfHlaIYIaZCLyqDKuZhYe0p", // asta e doar de test!!! trebuie schimbat cu unul real cand se testeaza
      mode: "payment",
      amount: "17",
      currency: "RON",
    },
    imgSrc: "acut.png",
  },
  {
    id: 2,
    name: "Pachet Check-up",
    catchPhrase: "...",
    description: "Pachetul ideal de prevenție, include:",
    descriptionArray: [
      {
        id: 1,
        item: "Anamneză",
      },
      {
        id: 2,
        item: "Evaluarea stării de sănătate",
      },
      {
        id: 3,
        item: "Măsurarea parametrilor vitali (Tensiune arterială, frecvența respiratorie, frecvența cardiacă, temperatura, pulsoximetrie etc)",
      },
      {
        id: 4,
        item: "Electrocardiogramă (EKG)",
      },
      {
        id: 5,
        item: "Teste rapide (Urină+Glicemie)",
      },
      {
        id: 6,
        item: "Auscultație (Cardiacă,Pulmonară)",
      },
      {
        id: 7,
        item: "Testare neurologică orientativă",
      },
      {
        id: 8,
        item: "Consult dermatologic orientativ : prevenția cancerului de piele",
      },
      {
        id: 9,
        item: "Discuție pe baza analizelor de sânge (dacă acestea există)",
      },
      {
        id: 10,
        item: "Recomandări medicale",
      },
      {
        id: 11,
        item: "Tratament (dacă e cazul)",
      },
    ],
    price: {
      stripeId: "price_1KwBqbAlr3jHVQDRBJGBE8Dy",
      mode: "payment",
      amount: "17",
      currency: "RON",
    },
    imgSrc: "check_up.png",
  },
  {
    id: 3,
    name: "Pachet Stress-free",
    catchPhrase: "...",
    description:
      "Oricine poate fi afectat de stres. Acesta poate fi cauza insomniilor, a durerilor de cap,a unui sistem imunitar slăbit sau chiar a unei alimentații greșite. De aceea am creat pentru tine acest pachet. Recomandat pentru:",
    descriptionArray: [
      {
        id: 1,
        item: "Reducerea nivelului de stres",
      },
      {
        id: 2,
        item: "Creșterea eficienței la locul de muncă sau în afacerea ta",
      },
      {
        id: 3,
        item: "Ameliorarea durerilor de cap sau de spate",
      },
      {
        id: 4,
        item: "Tratamentul fără medicamente ale insomniilor",
      },
      {
        id: 5,
        item: "Include: -1x ședință de masaj (de relaxare/terapeutic)",
      },
      {
        id: 6,
        item: "-1x ședință de psihoterapie",
      },
      {
        id: 7,
        item: "(Ședințele se vor desfășura la locația specialistului)",
      }
    ],
    price: {
      stripeId: "price_1KwBqbAlr3jHVQDRBJGBE8Dy",
      mode: "payment",
      amount: "17",
      currency: "RON",
    },
    imgSrc:"stress_free.png",
  },
  {
    id: 4,
    name: "VOLLI+",
    catchPhrase: "...",
    description: "Beneficiază de avantajele comunității PREMIUM Volli+",
    descriptionArray: [
      {
        id: 1,
        item: "Acces NELIMITAT la:",
      },
      {
        id: 2,
        item: "Grup privat Facebook",
      },
      {
        id: 3,
        item: "Serviciul Ask-a-Doc: On-line, vei putea să ceri părerea unui medic (WhatsApp sau Telegram). Ideal atunci când ești în concediu. Poți astfel să stabilești o legătură pe termen lung cu unul dintre specialiștii Volli.",
      },
      {
        id: 4,
        item: "Alegând acest pachet de beneficii , contribui direct la acțiunile cu scop caritabil Volli.",
      },
      {
        id: 5,
        item: "Pentru a menține calitatea serviciilor oferite, numărul de abonamente Volli + este limitat !",
      }

    ],
    price: {
      stripeId: "price_1OfKTMIYIaZCLyqD8vFDYs9I",
      mode: "subscription",
      amount: "30",
      currency: "RON",
    },
    imgSrc: "abonament.png",
  },
];
