import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { CloseButton, Modal, Table, Button } from "react-bootstrap";
import "../../styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo_volli.png";

function SubBenefitsModal(props) {
  console.log(props);

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.handleClose();
      }}
      className="services-modal modal fade"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <CloseButton
          onClick={() => {
            props.handleClose();
          }}
        />
      </Modal.Header>

      <Modal.Body>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <div className="modal-body">
                <h2 className="item-title ext-uppercase">
                  Beneficiile mele de abonat
                </h2>
                <p className="item-intro text-muted">
                  Lorem ipsum dolor sit amet consectetur.
                </p>
                <div className="item-description">
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th></th>
                        <th>STANDARD</th>
                        <th>PREMIUM</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td>0 €/luna</td>
                        <td>19 €/luna</td>
                      </tr>
                      <tr>
                        <td>ACUT:</td>
                        <td>40€</td>
                        <td>29€</td>
                      </tr>
                      <tr>
                        <td>CHECK-UP:</td>
                        <td>50€</td>
                        <td>35€</td>
                      </tr>
                      <tr>
                        <td>EXCLUSIV:</td>
                        <td>
                          <FontAwesomeIcon icon={faCircleXmark} />
                        </td>
                        <td>
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}></td>
                        <td>
                          <div>
                            Daca alegi pachetul PREMIUM participi direct la
                            actiunile de sanatate in scop caritabil VOLLI
                            CHANGE. Afla mai multe aici
                          </div>
                          <img
                            alt=""
                            src={logo}
                            width="40"
                            height="40"
                            className="d-inline-block align-top"
                          />
                          <div>
                            {" "}
                            Pentru a mentine standardul premium al serviciilor
                            oferite, locurile aferente acestui abonament sunt
                            <span style={{ color: "#F6BB42" }}> limitate</span>.
                          </div>
                          <Button
                            className="btn btn-primary btn-xl text-uppercase"
                            onClick={() => {
                              props.showServiceModal();
                              props.handleClose();
                            }}
                          >
                            Vreau pachet PREMIUM
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SubBenefitsModal;
