import "../../styles.css";
import "./services.css";

import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { serviceData } from "../../Helpers/serviceData";
import { Button } from "react-bootstrap";
import ServiceModal from "../Modals/ServiceModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

function Services(props) {
  const [show, setShow] = useState(false);
  const [stripePromise, setStripePromise] = useState(() => loadStripe(process.env.REACT_APP_STRIPE_KEY));

  const [selectedService, setSelectedService] = useState({});
  const handleClose = () => {
    console.log('se face handleClose');
    setShow(false);
  }
  const handleShow = (service) => {
    setSelectedService(service);
    setShow(true);
  };

  //const { loading, error, image } = useImage(fileName)

  React.useEffect(() => {
    if (props.userPerspective === true) {
      var servicesSection = document.getElementById("services");
      if (servicesSection !== null) {
        servicesSection.classList.add("main-wrapper");
      }
    }
  }, []);

  return (
    <section className="section bg-light" id="services">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Servicii</h2>
          <br />
          {/* <h3 className="section-subheading text-muted"></h3> */}
        </div>
        <div className="row">
          {serviceData.map((data, key) => {
            return (
              <Button
                variant="light"
                key={`services-service-button-${data.id}`}
                className="col-lg-4 col-sm-6 mb-4"
                onClick={() => {
                  handleShow(data);
                }}
              >
                <div className="service-item">
                  <img
                    className="img-fluid service-img top-rounded-edge"
                    src={require(`../../assets/images/services/${data.imgSrc}`).default}
                    alt="..."
                  />
                  <a className="service-item-overlay top-rounded-edge">
                    <div className="service-item-overlay-img top-rounded-edge">
                      <i className="fas fa-plus fa-3x"></i>
                    </div>
                  </a>
                </div>
                <div className="service-caption bottom-rounded-edge">
                  <div className="service-caption-heading">
                    {data.name}
                  </div>
                </div>
              </Button>
            );
          })}
        </div>
      </div>

      <Elements stripe={stripePromise}>
        <ServiceModal
          service={selectedService}
          show={show}
          handleClose={() => handleClose()}
          buyOption={props.userPerspective}
        />
      </Elements>
    </section>
  );
}
export default Services;
