import React, { useEffect, useState, useMemo } from "react";
import { Button } from "react-bootstrap";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFlexLayout,
  useResizeColumns,
  useRowSelect,
} from "react-table";
import "./tableStore.css";
import { GlobalFilter } from "./globalFilter";
import * as global from "../../Helpers/globalConsts";
import AssignModal from "../Modals/AssignModal";
import StatusModal from "../Modals/StatusModal";
import FinaliseModal from "../Modals/FinaliseModal";

export function TabelStore(props) {
  const data = useMemo(() => [...props.content], [props.content]);

  /*Assign Modal variables */
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedAssignRequest, setSelectedAssignRequest] = useState({});
  const handleCloseAssignModal = () => setShowAssignModal(false);
  const handleShowAssignModal = (request) => {
    setSelectedAssignRequest(request);
    setShowAssignModal(true);
  };
  /*------*/

  /*Status Modal variables */
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectedStatusRequest, setSelectedStatusRequest] = useState({});
  const handleCloseStatusModal = () => setShowStatusModal(false);
  const handleShowStatusModal = (request) => {
    setSelectedStatusRequest(request);
    setShowStatusModal(true);
  };
  /*------*/

  /*Finalise Modal variables */
  const [showFinaliseModal, setShowFinaliseModal] = useState(false);
  const [selectedFinaliseRequest, setSelectedFinaliseRequest] = useState({});
  const handleCloseFinaliseModal = () => setShowFinaliseModal(false);
  const handleFinaliseModal = (request) => {
    setSelectedFinaliseRequest(request);
    setShowFinaliseModal(true);
  };
  /*------*/

  const tableHooks = (hooks) => {
    if (props.table === "serviceRequest") {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: "Assign",
          Header: "Assign",
          Cell: ({ row }) => (
            <Button
              onClick={() => {
                handleShowAssignModal(row.values);
              }}
            >
              Assign
            </Button>
          ),
        },
      ]);
    }

    if (props.table === "onGoingRequest") {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: "Finalizare",
          Header: "Finalizare",
          accessor: "Finalizare",
          Cell: ({ row }) => (
            <Button
              disabled={row.values.Status === global.Asignat || row.values.Status === global.Reasignat || row.values.Status === global.InCurs}
              onClick={() => {
                handleFinaliseModal(row.values);
              }}
            >
              Procesare
            </Button>
          ),
        },
      ]);
    }
  };

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 400,
    }),
    []
  );

  const assignRequestColumns = useMemo(
    () => [
      {
        Header: "Cerere",
        columns: [
          {
            Header: "Id",
            accessor: "id",
          },
          {
            Header: "Email",
            accessor: "Email",
            width: 150,
          },
          {
            Header: "Nume",
            accessor: "NumeClient",
            width: 100,
          },
          {
            Header: "Prenume",
            accessor: "PrenumeClient",
            width: 100,
          },
          {
            Header: "Oras",
            accessor: "Oras",
            width: 65,
          },
          {
            Header: "Pachet",
            accessor: "Pachet",
            width: 65,
          },
          {
            Header: "Observatii",
            accessor: "Observatii",
            width: 200,
          },
          {
            Header: "DataInitiereCerere",
            accessor: "DataInitiereCerere",
          }
        ],
      },
    ],
    []
  );

  const historyColumns = useMemo(
    () => [
      {
        Header: "Cerere",
        columns: [
          {
            Header: "Id",
            accessor: "id",
          },
          {
            Header: "Email",
            accessor: "Email",
            width: 100,
          },
          {
            Header: "Nume",
            accessor: "NumeClient",
            width: 100,
          },
          {
            Header: "Prenume",
            accessor: "PrenumeClient",
            width: 100,
          },
          {
            Header: "Pachet",
            accessor: "Pachet",
            width: 50,
          },
          {
            Header: "Observatii",
            accessor: "Observatii",
            width: 200,
          },
        ],
      },
      {
        Header: "Asignare",
        columns: [
          {
            //pe viitor trebuie scos ca nu are sens sa fie acolo/ fiecare medic isi vede propriile servicii asignate
            Header: "Medic asignat",
            accessor: "MedicAsignat",
          },
          {
            Header: "Data asignare",
            accessor: "DataAsignarii",
          },
          {
            Header: "Asignator",
            accessor: "Asignator",
          },
          {
            Header: "Status",
            accessor: "Status",
          },
          {
            Header: "DataSetareStatus",
            accessor: "DataSetareStatusFinal",
          },
          {
            Header: "ObservatiiMedic",
            accessor: "ObservatiiMedic",
          },
          {
            Header: "DataFinalizare",
            accessor: "DataFinalizare",
          },
        ],
      },
    ],
    []
  );

  function statusDesign(status) {
    switch (status) {
      case global.Asignat:
        return "StatusAsignat";
      case global.Reasignat:
        return "StatusReasignat";
      case global.InCurs:
        return "StatusInCurs";
      case global.Rejectat:
        return "StatusRejectat";
      case global.Solutionat:
        return "StatusSolutionat";
    }
  }

  const pendingRequestColumns = useMemo(
    () => [
      {
        Header: "Cerere",
        columns: [
          {
            Header: "Id",
            accessor: "id",
          },
          {
            Header: "Email",
            accessor: "Email",
            width: 100,
          },
          {
            Header: "Nume",
            accessor: "NumeClient",
            width: 100,
          },
          {
            Header: "Prenume",
            accessor: "PrenumeClient",
            width: 100,
          },
          {
            Header: "Pachet",
            accessor: "Pachet",
            width: 50,
          },
          {
            Header: "Observatii",
            accessor: "Observatii",
            width: 200,
          },
        ],
      },
      {
        Header: "Asignare",
        columns: [
          {
            //pe viitor trebuie scos ca nu are sens sa fie acolo/ fiecare medic isi vede propriile servicii asignate
            Header: "Medic asignat",
            accessor: "MedicAsignat",
          },
          {
            Header: "Data asignare",
            accessor: "DataAsignarii",
          },
          {
            Header: "Asignator",
            accessor: "Asignator",
          },
          {
            Header: "Status",
            accessor: "Status",
            Cell: ({ row }) =>
              props.table === "pendingRequest" ? (
                <Button
                className={statusDesign(row.values.Status)}
                  onClick={() => {
                    handleShowStatusModal(row.values);
                  }}
                >
                  {row.values.Status}
                </Button>
              ) : (
                <div className={`dummyBtn ${statusDesign(row.values.Status)}`}>
                  {row.values.Status}
                </div>
              ),
          },
          {
            Header: "DataSetareStatus",
            accessor: "DataSetareStatusFinal",
          },
          {
            Header: "ObservatiiMedic",
            accessor: "ObservatiiMedic",
          },
        ],
      },
    ],
    []
  );

  let columns = null;
  if (props.table === "serviceRequest") {
    columns = assignRequestColumns;
  }
  if (props.table === "pendingRequest") {
    columns = pendingRequestColumns;
  }
  if (props.table === "onGoingRequest") {
    columns = pendingRequestColumns;
  }
  if (props.table === "historyRequest") {
    columns = historyColumns;
  }

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: [
          "id",
          "NumeClient",
          "PrenumeClient",
          "Asignator",
          "DataAsignarii",
          "DataSetareStatusFinal",
          "DataFinalizare",
          "DataInitiereCerere",
        ],
      },
      defaultColumn,
    },
    useGlobalFilter,
    tableHooks,
    useSortBy,
    useFlexLayout,
    useRowSelect,
    useResizeColumns
  );

  const {
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    getToggleHideAllColumnsProps,
    state,
    resetResizing,
  } = tableInstance;

  const isEven = (idx) => idx % 2 === 0;

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return <input type="checkbox" ref={resolvedRef} {...rest} />;
    }
  );

  return (
    <>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={state.globalFilter}
      />

      <div className="search-text inline">
        <Button onClick={resetResizing}>Reset Resizing</Button>
        <div>
          <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle
          All
        </div>
        {allColumns.map((column) => (
          <div key={column.id} className="inline">
            <label>
              <>
                <input type="checkbox" {...column.getToggleHiddenProps()} />{" "}
                {column.id}
              </>
            </label>
          </div>
        ))}
        <br />
      </div>

      <table
        className="table-general-style table-style"
        {...getTableBodyProps()}
      >
        <thead className="table-head">
          {headerGroups.map((headerGroup) => (
            <tr className="table-row" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className="table-header" {...column.getHeaderProps()}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    {column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""}
                  </div>
                  <div
                    {...column.getResizerProps()}
                    className={`resizer ${
                      column.isResizing ? "isResizing" : ""
                    }`}
                  />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="table-body" {...getTableBodyProps()}>
          {rows.map((row, idx) => {
            prepareRow(row);
            return (
              <tr
                className={isEven(idx) ? "table-row row-is-even" : "table-row"}
                {...row.getRowProps()}
              >
                {row.cells.map((cell, idx) => (
                  <td
                    className="table-data"
                    {...cell.getCellProps({
                      style: {
                        minWidth: cell.column.minWidth,
                        maxWidth: cell.column.maxWidth,
                      },
                    })}
                  >
                    {cell.render("Cell")}{" "}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {props.table === "serviceRequest" && (
        <AssignModal
          request={selectedAssignRequest}
          medicUsers={props.medicUsers}
          show={showAssignModal}
          handleClose={() => handleCloseAssignModal()}
        />
      )}
      {props.table === "pendingRequest" && (
        <StatusModal
          request={selectedStatusRequest}
          show={showStatusModal}
          handleClose={() => handleCloseStatusModal()}
        />
      )}

      {props.table === "onGoingRequest" && (
        <FinaliseModal
          request={selectedFinaliseRequest}
          show={showFinaliseModal}
          handleClose={() => handleCloseFinaliseModal()}
        />
      )}
    </>
  );
}
