import React, { useState } from "react";
import { Formik } from "formik";
import { Form, FloatingLabel, Button } from "react-bootstrap";
import * as yup from "yup";
import { useAuth } from "../../Contexts/AuthContext";
import YupPassword from "yup-password";
import { useNavigate } from "react-router-dom";
YupPassword(yup); // extend yup

export function SignUpSpecialist(props) {
  const { signupSpecialist } = useAuth();
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const schema = yup.object().shape({
    Nume: yup.string().required("Va rugam sa introduceti numele"),
    Prenume: yup.string().required("Va rugam sa introduceti prenumele"),
    Telefon: yup.string().matches(/^[0][7]\d{8}$/, {
      message: "Adaugati un numar de telefon valid (07xxxxxxxx)",
      excludeEmptyString: true,
    }),
    Email: yup.string().email("Adaugati o adresa de email valida").required(),
    Parola: yup
      .string()
      .required()
      .min(
        8,
        "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
      )
      .minLowercase(1, "password must contain at least 1 lower case letter")
      .minUppercase(1, "password must contain at least 1 upper case letter")
      .minNumbers(1, "password must contain at least 1 number")
      .minSymbols(1, "password must contain at least 1 special character"),
    ParolaConfirm: yup.string().when("Parola", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf([yup.ref("Parola")], "Ambele parole trebuie sa fie identice")
        .required("Confirmarea parolei este necesara"),
    }),
    OrasSelect: yup.string().required("Va rugam selectati un oraș"),
    DomeniuSelect: yup
      .string()
      .required("Va rugam selectati domeniul de specialitate"),
    Altele: yup.string().when("DomeniuSelect", {
      is: (DomeniuSelect) => DomeniuSelect === "Altele",
      then: yup
        .string()
        .required(
          "Explicatii cu privire la domeniul de activitate sunt necesare"
        )
        .min(10, "Minim 10 caractere "),
    }),
    Terms: yup
      .bool()
      .oneOf([true], "Acceptarea termeniilor si a conditiilor este necesara"),
  });

  const handleSubmit = async (values) => {
    console.log("testtt: ", values.Email, values.Parola);
    try {
      setError("");
      setIsLoading(true);
      await signupSpecialist(values.Email, values.Parola, values);
    } catch (e) {
      setError("Nu s-a putut creea contul.");
      console.log("Error" + e);
    }

    setIsLoading(false);
  };

  return (
    <>
      <section className="main-wrapper">
        <div className="emailAndPassContainer">
          Creeaza cont de utilizator Volli
          <Formik
            validationSchema={schema}
            validateOnChange={validateAfterSubmit}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            initialValues={{
              Nume: "",
              Prenume: "",
              Telefon: "",
              Email: "",
              Parola: "",
              ParolaConfirm: "",
              OrasSelect: "",
              DomeniuSelect: "",
              Altele: "",
              Terms: false,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              isSubmitting,
            }) => (
              <Form
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  console.log("submit");
                  console.log(values);
                  console.log(errors);
                  setValidateAfterSubmit(true);
                  handleSubmit();
                }}
              >
                <FloatingLabel key="Input-Nume" label="Nume" className="mb-3">
                  <Form.Control
                    type="text"
                    name="Nume"
                    value={values.Nume}
                    onChange={handleChange}
                    isValid={touched.Nume && !errors.Nume}
                    isInvalid={!!errors.Nume}
                  />
                  <Form.Control.Feedback className="d-block" type="invalid">
                    {errors.Nume}
                  </Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel
                  key="Input-Prenume"
                  label="Prenume"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    name="Prenume"
                    value={values.Prenume}
                    onChange={handleChange}
                    isValid={touched.Prenume && !errors.Prenume}
                    isInvalid={!!errors.Prenume}
                  />
                  <Form.Control.Feedback className="d-block" type="invalid">
                    {errors.Prenume}
                  </Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel key="Input-Email" label="Email" className="mb-3">
                  <Form.Control
                    type="text"
                    name="Email"
                    value={values.Email}
                    onChange={handleChange}
                    isValid={touched.Email && !errors.Email}
                    isInvalid={!!errors.Email}
                  />
                </FloatingLabel>
                <Form.Control.Feedback className="d-block" type="invalid">
                  {errors.Email}
                </Form.Control.Feedback>

                <FloatingLabel
                  key="Input-Parola"
                  label="Parola"
                  className="mb-3"
                >
                  <Form.Control
                    type="password"
                    name="Parola"
                    value={values.Parola}
                    onChange={handleChange}
                    isValid={touched.Parola && !errors.Parola}
                    isInvalid={!!errors.Parola}
                  />
                  <Form.Control.Feedback className="d-block" type="invalid">
                    {errors.Parola}
                  </Form.Control.Feedback>
                </FloatingLabel>

                <FloatingLabel
                  key="Input-ParolaConfirm"
                  label="Confirma parola"
                  className="mb-3"
                >
                  <Form.Control
                    type="password"
                    name="ParolaConfirm"
                    value={values.ParolaConfirm}
                    onChange={handleChange}
                    isValid={
                      (touched.ParolaConfirm && !errors.ParolaConfirm) ||
                      (touched.ParolaConfirm && !errors.Parola)
                    }
                    isInvalid={!!errors.ParolaConfirm || !!errors.Parola}
                  />
                  <Form.Control.Feedback className="d-block" type="invalid">
                    {errors.ParolaConfirm || errors.Parola}
                  </Form.Control.Feedback>
                </FloatingLabel>

                <FloatingLabel key="Input-Oras" label="Oras" className="mb-3">
                  <Form.Select
                    name="OrasSelect"
                    value={values.OrasSelect}
                    onChange={handleChange}
                    isValid={touched.OrasSelect && !errors.OrasSelect}
                    isInvalid={!!errors.OrasSelect}
                  >
                    <option value="invalid">Selectează un oraș</option>
                    <option value="Cluj">Cluj</option>
                    <option value="Timisoara">Timisoara</option>
                  </Form.Select>
                  <Form.Control.Feedback className="d-block" type="invalid">
                    {errors.OrasSelect}
                  </Form.Control.Feedback>
                </FloatingLabel>

                <FloatingLabel
                  key="Input-DomeniuSelect"
                  label="Domeniu"
                  className="mb-3"
                >
                  <Form.Select
                    name="DomeniuSelect"
                    value={values.DomeniuSelect}
                    onChange={handleChange}
                    isValid={touched.DomeniuSelect && !errors.DomeniuSelect}
                    isInvalid={!!errors.DomeniuSelect}
                  >
                    <option value="invalid">Selectează un oraș</option>
                    <option value="1">1</option>
                    <option value="Altele">Altele</option>
                  </Form.Select>
                </FloatingLabel>

                {values.DomeniuSelect === "Altele" && (
                  <>
                    {" "}
                    <FloatingLabel
                      key="Input-Altele"
                      label="Explicati domeniu de activitate:"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="Altele"
                        value={values.Altele}
                        onChange={handleChange}
                        isValid={touched.Altele && !errors.Altele}
                        isInvalid={!!errors.Altele}
                      />
                    </FloatingLabel>
                    <Form.Control.Feedback className="d-block" type="invalid">
                      {errors.Altele}
                    </Form.Control.Feedback>
                  </>
                )}

                <div className="form-control padding-terms mb-3">
                  <Form.Check
                    type="checkbox"
                    id="terms-checkbox"
                    label="Accept termenii si conditiile"
                    name="Terms"
                    value={values.Terms}
                    onChange={handleChange}
                    isValid={touched.Terms && !errors.Terms}
                    isInvalid={touched.Terms && !!errors.Terms}
                  />
                </div>
                <Form.Control.Feedback className="d-block" type="invalid">
                  {errors.Terms}
                </Form.Control.Feedback>
                <Button type="submit">
                  {isLoading
                    ? "Se incarca..."
                    : "Inregistreaza-te ca si Expert Volli"}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
}
