import "../../styles.css";

const Cancel = () => {
  return (
    <section id="dashboard1" className="main-wrapper dashboard-fixed">
      <h1>Optiune de cumpararea intrerupta.</h1>
    </section>
  );
};

export default Cancel;
