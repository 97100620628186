import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import {
  Button,
  CloseButton,
  Modal,
  Form,
  FloatingLabel,
} from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useAuth } from "../../Contexts/AuthContext";

function UpdatePasswordModal(props) {
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const { updatePass } = useAuth();
  console.log("StatusModal props: ", props);

  const handleSubmit = (values, { setSubmitting }) => {
    updatePass(values.CurrentPassword, values.NewPassword)
      .then(console.log("update la parola"))
      .catch((error) => {
        console.log("err reauth:", error["code"]);
        switch (error["code"]) {
          case "auth/wrong-password":
            alert("Parola introdusa in campul 'Parola curenta' este gresita.");
            break;
          case "auth/too-many-requests":
            alert(
              "Au fost facute prea multe incercari esuate in a schimba parola. Te rugam revino in cateva minute!"
            );
            break;
          default:
            alert(
              "A aparut o eroare la schimba parola. Te rugam incerca din nou!"
            );
            break;
        }
      });
    setSubmitting(false);
  };

  const schema = yup.object().shape({
    CurrentPassword: yup.string().required("Va rugam introduceti parola!"),
    NewPassword: yup
      .string()
      .required("Va rugam introduceti parola noua.")
      .min(5, "Parola trebuie sa contina minim 6 caractere."),
    ConfNewPassword: yup
      .string()
      .required("Va rugam reintroduceti parola noua.")
      .oneOf([yup.ref("NewPassword"), null], "Va rugam confirmati parola."),
  });

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      className="services-modal modal fade"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <CloseButton onClick={props.handleClose} />
      </Modal.Header>

      <Modal.Body>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <div className="modal-body">
                <h2 className="item-title ext-uppercase">Schimba parola</h2>

                <Formik
                  validationSchema={schema}
                  validateOnChange={validateAfterSubmit}
                  onSubmit={(values, { setSubmitting }) => {
                    console.log("subm:", { setSubmitting });
                    handleSubmit(values, { setSubmitting });
                  }}
                  initialValues={{
                    CurrentPassword: "",
                    NewPassword: "",
                    ConfNewPassword: "",
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    isSubmitting,
                  }) => (
                    <Form
                      noValidate
                      onSubmit={(e) => {
                        e.preventDefault();
                        setValidateAfterSubmit(true);
                        handleSubmit();
                      }}
                    >
                      <FloatingLabel
                        key="CurrentPassword"
                        label="Parola curenta"
                        className="mb-3"
                      >
                        <Form.Control
                          type="password"
                          name="CurrentPassword"
                          onChange={handleChange}
                          value={values.CurrentPassword}
                          isInvalid={!!errors.CurrentPassword}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.CurrentPassword}
                        </Form.Control.Feedback>
                      </FloatingLabel>

                      <FloatingLabel
                        key="NewPassword"
                        label="Parola noua"
                        className="mb-3"
                      >
                        <Form.Control
                          type="password"
                          name="NewPassword"
                          onChange={handleChange}
                          value={values.NewPassword}
                          isValid={
                            values.NewPassword !== "" &&
                            values.NewPassword === values.ConfNewPassword
                              ? true
                              : false
                          }
                          isInvalid={!!errors.NewPassword}
                        />
                      </FloatingLabel>

                      <FloatingLabel
                        key="ConfNewPassword"
                        label="Confirma parola noua"
                        className="mb-3"
                      >
                        <Form.Control
                          type="password"
                          name="ConfNewPassword"
                          onChange={handleChange}
                          value={values.ConfNewPassword}
                          isValid={
                            values.NewPassword !== "" &&
                            values.NewPassword === values.ConfNewPassword
                              ? true
                              : false
                          }
                          isInvalid={!!errors.ConfNewPassword}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.ConfNewPassword}
                        </Form.Control.Feedback>
                      </FloatingLabel>

                      <Button onClick={() => console.log("err,", errors)}>
                        test
                      </Button>

                      <Button type="submit" disabled={isSubmitting}>
                        Schimba parola
                      </Button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default UpdatePasswordModal;
