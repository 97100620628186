import React, { useContext, useEffect, useState } from "react";
import { auth } from "../Auth/firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  signOut,
  EmailAuthProvider,
  sendPasswordResetEmail,
  updatePassword,
  reauthenticateWithCredential,
} from "firebase/auth";
import { db } from "../Auth/firebase";
import {
  collection,
  getDocs,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [specialistVerification, setSpecialistVerification] = useState(false);
  const [adminRights, setAdminRights] = useState({hasRights: false, area: null});
  const [medicRights, setMedicRights] = useState({hasRights: false, area: null});
  const [loading, setLoading] = useState(true); // by default it is always loading

  useEffect(() => {
    if (auth.currentUser && auth.currentUser.emailVerified === true)
      setSpecialistVerification(false);
  }, [auth.currentUser]);

  async function signup(email, password) {
    createUserWithEmailAndPassword(auth, email, password).then(
      (userCredential) => {
        sendEmailVerification(auth.currentUser);
      }
    );

    return true;
  }

  async function signupSpecialist(email, password, values) {
    createUserWithEmailAndPassword(auth, email, password).then(
      (userCredential) => {
        setSpecialistVerification(true);
        sendEmailVerification(auth.currentUser);
        console.log("s-a trimis email??", auth.currentUser);
        fetch(`${process.env.REACT_APP_BASE_SERVER_URL}/verifySpecialist`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(values),
        }).then((response) => {
          if (response.status === 200) {
            console.log("a mers", response);
          } else console.log("NU a mers", response);
        });
      }
    );

    return true;
  }

  function verifyemail() {
    return sendEmailVerification(auth.currentUser);
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function updatePass(currentPassword, newPassword) {
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      currentPassword
    );
    return reauthenticateWithCredential(auth.currentUser, credential).then(() =>
      updatePassword(auth.currentUser, newPassword).then(
        console.log("se scchimba parola")
      )
    );
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      console.log("Aici se steaza currentUser=", user);
      setCurrentUser(user);
      setLoading(false); //if there is any logged in user, loading is set to false
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const getSubscribers = async () => {
      const q = query(
        collection(db, "ConfirmedSubscribers"),
        where("email", "==", `${currentUser.email}`)
      );

      //listening for changes to ConfirmSubscribers collection(e.g: when canncelling sub)
      onSnapshot(q, (snapshot) => {
        console.log("onSnapshot isSubscribed ", snapshot.docs);
        if (snapshot.docs.length === 0) {
          setIsSubscribed(false);
        } else setIsSubscribed(true);
      });

      // const data = await getDocs(collection(db, "ConfirmedSubscribers"));
      // try {
      //   const user = data.docs.find(
      //     (x) => x.data().email === currentUser.email
      //   );
      //   if (user && user.data()) {
      //     setIsSubscribed(true);
      //   }
      // } catch (e) {
      //   console.log(e);
      // }
    };

    const getMedicUsers = async () => {
      const data = await getDocs(collection(db, "medicUsers"));
      try {
        const user = data.docs.find(
          (x) => x.data().email === currentUser.email
        );
        if (user && user.data()) {
          setMedicRights({hasRights: true, area: user.data().area});
        }
      } catch (e) {
        console.log("Error:" + e);
      }
    };

    const getAdminUsers = async () => {
      const data = await getDocs(collection(db, "adminUsers"));

      try {
        const user = data.docs.find(
          (x) => x.data().email === currentUser.email
        );
        if (user && user.data()) {
          console.log('DEBUG getAdminUsers:', user.data());
          setAdminRights({hasRights: true, area: user.data().area});
        }
      } catch (e) {
        console.log("Error:" + e);
      }
    };

    if (currentUser) {
      getMedicUsers();
      getSubscribers();
      getAdminUsers();
    }
  }, [currentUser]);

  const value = {
    currentUser,
    specialistVerification,
    isSubscribed,
    adminRights: adminRights,
    medicRights: medicRights,
    setadminRights: setAdminRights,
    signup,
    signupSpecialist,
    verifyemail,
    login,
    logout,
    resetPassword,
    updatePass,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
