import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "../../styles.css";
import { useAuth } from "../../Contexts/AuthContext";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import UpdatePasswordModal from "../../Components/Modals/UpdatePasswordModal";
import SubBenefitsModal from "../../Components/Modals/SubBenefitsModal";
import CancelSubModal from "../../Components/Modals/CancelSubModal";
import ServiceModal from "../../Components/Modals/ServiceModal";
import {serviceData, SUBSCRIPTION_SERVICE_ID} from "../../Helpers/serviceData";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

export default function Dashboard() {
  const { currentUser, isSubscribed, adminRights, medicRights } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const [giveOptions, setGiveOptions] = useState(false);

  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);
  const handleClose_UpdatePassword = () => setShowUpdatePasswordModal(false);

  const [showSubBenefitsModal, setShowSubBenefitsModal] = useState(false);
  const handleClose_SubBenefits = () => setShowSubBenefitsModal(false);

  const [showCloseSubModal, setShowCancelSubModal] = useState(false);
  const handleClose_CancelSub = () => setShowCancelSubModal(false);

  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(process.env.REACT_APP_STRIPE_KEY)
  );
  const [showServiceModal, setShowServiceModal] = useState(false);
  const handleClose_Service = () => {
    console.log("se face handleClose in handleClose_Service");
    setShowServiceModal(false)
  };

  let navigate = useNavigate();

  useEffect(() => {
    console.log("isSubscribed= ", isSubscribed);
  }, [isSubscribed]);

  return (
    <>
      <section id="dashboard" className="main-wrapper dashboard-fixed">
        <div className="dashboardContainer">
          <Formik
            initialValues={{
              email: currentUser.email,
              name: "",
            }}
          >
            {({ handleChange, initialValues, values }) => (
              <>
                <div className="section-heading dashboard-title">
                  Profil
                  <span>
                    {!isEdit && (
                      <Button
                        onClick={() => {
                          setIsEdit(true);
                          setGiveOptions(true);
                        }}
                        className="edit-button"
                      >
                        Editare
                      </Button>
                    )}

                    {giveOptions && (
                      <>
                        <Button
                          onClick={() => {
                            setIsEdit(false);
                            setGiveOptions(false);
                          }}
                          className="edit-button"
                        >
                          Anuleaza
                        </Button>
                        <Button
                          onClick={() => {
                            setIsEdit(true);
                          }}
                          onChange={handleChange}
                          disabled={
                            initialValues.email !== values.email ? false : true
                          }
                          className="edit-button"
                        >
                          Salveaza
                        </Button>
                      </>
                    )}

                    {(adminRights.hasRights || medicRights.hasRights) && (
                      <Button
                        onClick={() => navigate("/dashboard/tableManager")}
                      >
                        Go to table manager
                      </Button>
                    )}
                  </span>
                </div>
                <div className="dashboard-title">
                  <Form.Group as={Row} className="mb-3" controlId="userData">
                    <Form.Label column sm="2">
                      Email
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        disabled={!isEdit}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="2">
                      Parola
                    </Form.Label>
                    <Col>
                      {!isEdit ? (
                        <Form.Control
                          type="password"
                          value="parola"
                          disabled={!isEdit}
                        />
                      ) : (
                        <Button
                          onChange={handleChange}
                          onClick={() => setShowUpdatePasswordModal(true)}
                        >
                          Schimbare parola
                        </Button>
                      )}
                    </Col>

                  </Form.Group>
                  <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="2"/>
                    <Col>
                          <Button
                              onChange={handleChange}
                              disabled={!isEdit}
                              onClick={() => setShowUpdatePasswordModal(true)}
                          >
                            Stergere cont
                          </Button>
                    </Col>

                  </Form.Group>
                </div>
                <div className="section-heading dashboard-title">Abonament</div>
                <div className="dashboard-title">
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="SubscribedStatus"
                  >
                    <Form.Label column sm="2">
                      Status
                    </Form.Label>
                    <Col sm="10">{isSubscribed ? "Abonat" : "Dezabonat"}</Col>

                    <Button
                      onClick={() => {
                        setShowSubBenefitsModal(true);
                      }}
                    >
                      Beneficiile mele
                    </Button>

                    {isSubscribed && (
                      <Button
                        onClick={() => {
                          setShowCancelSubModal(true);
                        }}
                      >
                        Inchide abonament
                      </Button>
                    )}
                  </Form.Group>
                </div>
              </>
            )}
          </Formik>
        </div>

        <UpdatePasswordModal
          show={showUpdatePasswordModal}
          handleClose={() => handleClose_UpdatePassword()}
        />
        <SubBenefitsModal
          show={showSubBenefitsModal}
          showServiceModal = {() => setShowServiceModal(true)}
          handleClose={() => handleClose_SubBenefits()}
        />

        <CancelSubModal
          show={showCloseSubModal}
          handleClose={() => handleClose_CancelSub()}
        />

        <Elements stripe={stripePromise}>
          <ServiceModal
            service={serviceData[serviceData.length - 1]} // Subscipton is always the last element in the array
            show={showServiceModal}
            handleClose={() => handleClose_Service()}
            buyOption={true}
          />
        </Elements>
      </section>
    </>
  );
}
