import React, { useState } from "react";
import { Formik } from "formik";
import { Form, FloatingLabel, Button, Alert } from "react-bootstrap";
import * as yup from "yup";
import { useAuth } from "../../Contexts/AuthContext";
import YupPassword from "yup-password";
import { TermsModal } from "../../Components/Modals/TermsModal";
import { useNavigate } from "react-router-dom";
YupPassword(yup); // extend yup

export function SignUp(props) {
  const navigate = useNavigate();
  const { signup } = useAuth();
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");

  const [showTermsModal, setShowTermsModal] = useState(false);
  const handleClose_TermsModal = () => setShowTermsModal(false);

  const schema = yup.object().shape({
    Nume: yup.string().required("Va rugam sa introduceti numele"),
    Prenume: yup.string().required("Va rugam sa introduceti prenumele"),
    Telefon: yup.string().matches(/^[0][7]\d{8}$/, {
      message: "Adaugati un numar de telefon valid (07xxxxxxxx)",
      excludeEmptyString: true,
    }),
    Email: yup.string().email("Adaugati o adresa de email valida").required(),
    Parola: yup
      .string()
      .required()
      .min(
        8,
        "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
      )
      .minLowercase(1, "password must contain at least 1 lower case letter")
      .minUppercase(1, "password must contain at least 1 upper case letter")
      .minNumbers(1, "password must contain at least 1 number")
      .minSymbols(1, "password must contain at least 1 special character"),
    ParolaConfirm: yup.string().when("Parola", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf([yup.ref("Parola")], "Ambele parole trebuie sa fie identice")
        .required("Confirmarea parolei este necesara"),
    }),
    Terms: yup
      .bool()
      .oneOf([true], "Acceptarea termeniilor si a conditiilor este necesara"),
  });

  const handleSubmit = async (values) => {
    console.log("testtt: ", values.Email, values.Parola);
    try {
      setError("");
      setIsLoading(true);
      if (await signup(values.Email, values.Parola)) {
        // const DisplayMessage = `Un email de verificare a fost trimis catre ${values.Email}`;
        // // navigate(`/success/${DisplayMessage}`);
      }
    } catch (e) {
      setError("Nu s-a putut creea contul.");
      console.log("Error" + e);
    }

    setIsLoading(false);
  };

  return (
    <>
      <section className="main-wrapper">
        <div className="emailAndPassContainer">
          <div className="item-title ext-uppercase">
            Creeaza cont de utilizator Volli
          </div>
          <Formik
            validationSchema={schema}
            validateOnChange={validateAfterSubmit}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            initialValues={{
              Nume: "",
              Prenume: "",
              Telefon: "",
              Email: "",
              Parola: "",
              ParolaConfirm: "",
              Terms: false,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              isSubmitting,
            }) => (
              <Form
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  console.log("submit");
                  console.log(values);
                  console.log(errors);
                  setValidateAfterSubmit(true);
                  handleSubmit();
                }}
              >
                <FloatingLabel key="Input-Nume" label="Nume" className="mb-3">
                  <Form.Control
                    type="text"
                    name="Nume"
                    value={values.Nume}
                    onChange={handleChange}
                    isValid={touched.Nume && !errors.Nume}
                    isInvalid={!!errors.Nume}
                  />
                  <Form.Control.Feedback className="d-block" type="invalid">
                    {errors.Nume}
                  </Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel
                  key="Input-Prenume"
                  label="Prenume"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    name="Prenume"
                    value={values.Prenume}
                    onChange={handleChange}
                    isValid={touched.Prenume && !errors.Prenume}
                    isInvalid={!!errors.Prenume}
                  />
                  <Form.Control.Feedback className="d-block" type="invalid">
                    {errors.Prenume}
                  </Form.Control.Feedback>
                </FloatingLabel>
                <FloatingLabel key="Input-Email" label="Email" className="mb-3">
                  <Form.Control
                    type="text"
                    name="Email"
                    value={values.Email}
                    onChange={handleChange}
                    isValid={touched.Email && !errors.Email}
                    isInvalid={!!errors.Email}
                  />
                </FloatingLabel>
                <Form.Control.Feedback className="d-block" type="invalid">
                  {errors.Email}
                </Form.Control.Feedback>

                <FloatingLabel
                  key="Input-Parola"
                  label="Parola"
                  className="mb-3"
                >
                  <Form.Control
                    type="password"
                    name="Parola"
                    value={values.Parola}
                    onChange={handleChange}
                    isValid={touched.Parola && !errors.Parola}
                    isInvalid={!!errors.Parola}
                  />
                  <Form.Control.Feedback className="d-block" type="invalid">
                    {errors.Parola}
                  </Form.Control.Feedback>
                </FloatingLabel>

                <FloatingLabel
                  key="Input-ParolaConfirm"
                  label="Confirma parola"
                  className="mb-3"
                >
                  <Form.Control
                    type="password"
                    name="ParolaConfirm"
                    value={values.ParolaConfirm}
                    onChange={handleChange}
                    isValid={
                      (touched.ParolaConfirm && !errors.ParolaConfirm) ||
                      (touched.ParolaConfirm && !errors.Parola)
                    }
                    isInvalid={!!errors.ParolaConfirm || !!errors.Parola}
                  />
                  <Form.Control.Feedback className="d-block" type="invalid">
                    {errors.ParolaConfirm || errors.Parola}
                  </Form.Control.Feedback>
                </FloatingLabel>

                <div className="form-control padding-terms mb-3">
                  <Form.Check
                    type="checkbox"
                    id="terms-checkbox"
                    label={"Accept termenii si conditiile"}
                    name="Terms"
                    value={values.Terms}
                    onChange={handleChange}
                    isValid={touched.Terms && !errors.Terms}
                    isInvalid={touched.Terms && !!errors.Terms}
                  />
                </div>
                <Form.Control.Feedback
                  className="d-block"
                  onClick={() => {
                    setShowTermsModal(true);
                  }}
                >
                  Click aici pt a citi T si C
                </Form.Control.Feedback>
                <Form.Control.Feedback className="d-block" type="invalid">
                  {errors.Terms}
                </Form.Control.Feedback>

                <Button type="submit">
                  {isLoading ? "Se incarca..." : "Inregistreaza-te"}
                </Button>
              </Form>
            )}
          </Formik>
          {error && <Alert variant="danger">{error}</Alert>}
        </div>
      </section>

      <TermsModal
        show={showTermsModal}
        handleClose={() => handleClose_TermsModal()}
      />
    </>
  );
}
