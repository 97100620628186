import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { CloseButton, Button, Modal } from "react-bootstrap";
import "../../styles.css";
import { Checkout } from "./Checkout";
import { useAuth } from "../../Contexts/AuthContext";

function CancelSubModal(props) {
  const { currentUser, isSubscribed } = useAuth();

  const handleSubmit = async () => {
    if (isSubscribed) {
      const data = { email: currentUser.email };
      console.log("fetch/cancel-sub");
      fetch(`${process.env.REACT_APP_BASE_SERVER_URL}/cancel-subscription`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (response) {
          props.handleClose();
          return console.log("response final=", response);
        });
    }
  };
  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.handleClose();
      }}
      className="services-modal modal fade"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <CloseButton
          onClick={() => {
            props.handleClose();
          }}
        />
      </Modal.Header>

      <Modal.Body>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <div className="modal-body">
                <h2 className="item-title ext-uppercase">Abonamentul meu</h2>
                <p className="item-intro text-muted">
                  Lorem ipsum dolor sit amet consectetur.
                </p>
                <img
                  className="img-fluid d-block mx-auto mb-3"
                  //src={props.service.imgSrc}
                  alt="..."
                />
                <div className="item-description">
                  {/* <p>{props.service.description}</p>
                  <p>{props.service.descriptionMiddlePhrase}</p> */}
                  {/* <ul className="list-inline ">
                    {props.service.descriptionArray?.map((data, key) => {
                      return <li>{data.item}</li>;
                    })}
                  </ul> */}
                  Abonamentele Volli sunt oferite intr-un numar limitat. Esti
                  sigur ca vrei sa renunti la acest beneficiu?
                  <Button onClick={handleSubmit}>Inchide abonament</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CancelSubModal;
