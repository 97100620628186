import "bootstrap/dist/css/bootstrap.min.css";
import { CloseButton, Modal } from "react-bootstrap";
import DocViewerWrapper from "../../Pages/Generics/DocViewerWrapper";

export function TermsModal(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      className="services-modal modal fade"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <CloseButton onClick={props.handleClose} />
      </Modal.Header>

      <Modal.Body>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <div className="modal-body">
                <h2 className="item-title ext-uppercase">
                  Termeni si conditii
                </h2>
                <DocViewerWrapper DocumentName="T&C.pdf" />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
