import React, {useState} from "react";
import {useStripe} from "@stripe/react-stripe-js";
import {Formik} from "formik";
import {Form, FloatingLabel, Button, Alert, Row, Col} from "react-bootstrap";
import * as yup from "yup";
import {useAuth} from "../../Contexts/AuthContext";
import {getDateTime} from "./ModalLogic";
import {faBookOpen} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TermsModal} from "./TermsModal";
import {CITIES} from "../../Helpers/globalConsts";

export function Checkout(props) {
    const [error, setErrror] = useState("");
    const {currentUser} = useAuth();
    const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

    const [showTermsModal, setShowTermsModal] = useState(false);
    const handleClose_TermsModal = () => setShowTermsModal(false);

    console.log('Checkout props', props);

    const schema = yup.object().shape({
        Nume: yup.string().required("Va rugam sa introduceti numele"),
        Prenume: yup.string().required("Va rugam sa introduceti prenumele"),
        OrasSelect: yup.string().required("Va rugam selectati un oraș"),
        Observatii: yup.string(),
        ObservatiiProgramare: yup
            .string()
            .required(
                "Va rugam introduceti optiune care vi se potriveste cel mai bine"
            ),
        Telefon: yup
            .string()
            .required("Va rugam introduceti numarul de telefon")
            .matches(/^[0][7]\d{8}$/, {
                message: "Adaugati un numar de telefon valid (07xxxxxxxx)",
            }),
        Strada: yup.string().required("Va rugam introduceti strada"),
        Numarul: yup.string().required("Va rugam introduceti numarul"),
        Terms: yup
            .bool()
            .oneOf([true], "Acceptarea termeniilor si a conditiilor este necesara"),
        OtherPersonCheckBox: yup.bool(),
        OtherPersonEmail: yup.string().when('OtherPersonCheckBox', {
            is: (OtherPersonCheckBox) => OtherPersonCheckBox === true,
            then: yup.string().required('Va rugam introduceti adresa de email a persoanei care va beneficia de serviciu'),
            otherwise: yup.string()
        }),

    });

    function getPriceValue() {
        if (props.service.price.mode === "payment")
            return `${props.service.price.amount} ${props.service.price.currency}`;
        else if (props.service.price.mode === "subscription")
            return `${props.service.price.amount} ${props.service.price.currency}/luna`;
    }

    const handleSubmit = async (values, actions) => {
        actions.setSubmitting(true);
        setErrror("");

        let obs = null;
        if (values.Observatii === "") {
            obs = "Nu exista observatii";
        } else {
            obs = values.Observatii;
        }
        const orderData = {
            Email: currentUser.email,
            NumeClient: values.Nume,
            PrenumeClient: values.Prenume,
            Pachet: props.service.name,
            Observatii: obs,
            ObservatiiProgramare: values.ObservatiiProgramare,
            Oras: values.OrasSelect,
            Strada: values.Strada,
            Numarul: values.Numarul,
            Telefon: values.Telefon,
            Pret: props.service.price.amount,
            DataInitiereCerere: getDateTime(),
            OtherPersonCheckBox: values.OtherPersonCheckBox,
            OtherPersonEmail: values.OtherPersonEmail,
        };

        const item = props.service.price;

        const data = {
            orderData: orderData,
            item: item,
        };

        if (values.OtherPersonCheckBox === true && values.OtherPersonEmail !== "") {
            console.log('Data in checkout', data);
            // alert('TREBUIE TRIMIS EMAIL LA ' + values.OtherPersonEmail + ' CU LINK-UL DE PLATA');
            fetch(`${process.env.REACT_APP_BASE_SERVER_URL}/send-checkout-session-on-email`, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(data),
            }).then(function (response) {
                console.log('Response:', response);
                if (response.status === 200) {
                    console.log("a mers", response);
                    props.handleClose();
                } else console.log("NU a mers", response);
            });
            actions.setSubmitting(false);
        } else {
            fetch(`${process.env.REACT_APP_BASE_SERVER_URL}/process-payment`, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(data),
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (response) {
                    console.log(response);
                    if (response.status === 200)
                        return window.location.replace(response.redirectUrl);
                    else return setErrror(response.message);
                })
                .then(function (result) {
                    // If `redirectToCheckout` fails due to a browser or network
                    // error, you should display the localized error message to your
                    // customer using `error.message`.
                    console.log(result);
                    if (result && result.error) {
                        console.log("Error=", result.error);
                        setErrror(
                            "Redirectionarea nu a fost posibilia. Va rugam verificati conexiunea la internet si incercati din nou."
                        );
                        //props.handleClose();
                    }
                });
            actions.setSubmitting(false);
        }
    };

    return (
        <>
            <Formik
                validationSchema={schema}
                validateOnChange={validateAfterSubmit}
                onSubmit={(values, actions) => {
                    handleSubmit(values, actions);
                }}
                initialValues={{
                    Nume: "",
                    Prenume: "",
                    OrasSelect: "",
                    Observatii: "",
                    Terms: false,
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                      touched,
                      isSubmitting,
                  }) => (
                    <Form
                        noValidate
                        onSubmit={(e) => {
                            e.preventDefault();
                            setValidateAfterSubmit(true);
                            handleSubmit();
                        }}
                    >
                        <FloatingLabel key="Input-Nume" label="Nume" className="mb-3">
                            <Form.Control
                                type="text"
                                name="Nume"
                                value={values.Nume}
                                onChange={handleChange}
                                isValid={touched.Nume && !errors.Nume}
                                isInvalid={!!errors.Nume}
                            />
                            <Form.Control.Feedback className="d-block" type="invalid">
                                {errors.Nume}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel key="Input-Prenume" label="Prenume" className="mb-3">
                            <Form.Control
                                type="text"
                                name="Prenume"
                                value={values.Prenume}
                                onChange={handleChange}
                                isValid={touched.Prenume && !errors.Prenume}
                                isInvalid={!!errors.Prenume}
                            />
                            <Form.Control.Feedback className="d-block" type="invalid">
                                {errors.Prenume}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel key="Input-Email" label="Email" className="mb-3">
                            <Form.Control
                                type="text"
                                name="Email"
                                value={currentUser.email}
                                readOnly
                            />
                        </FloatingLabel>
                        {props.service.price.mode === "payment" &&
                            <div className="form-control padding-terms mb-3">
                                <Form.Check
                                    type="checkbox"
                                    id="OtherPersonCheckBox"
                                    label="Achiziționez acest servciu pentru altcineva"
                                    name="OtherPersonCheckBox"
                                    value={values.OtherPersonCheckBox}
                                    onChange={handleChange}
                                />
                            </div>
                        }
                        {
                            values.OtherPersonCheckBox &&
                            <FloatingLabel key="Input-OtherPersonEmail" label="Email persoana terta:" className="mb-3">
                                <Form.Control
                                    type="text"
                                    name="OtherPersonEmail"
                                    value={values.otherPersonEmail}
                                    isValid={touched.otherPersonEmail && !errors.otherPersonEmail}
                                    isInvalid={!!errors.otherPersonEmail}
                                    onChange={handleChange}
                                />
                                <Form.Control.Feedback className="d-block" type="invalid">
                                    {errors.OtherPersonEmail}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        }
                        <Row className="g-2">
                            <Col md>
                                <FloatingLabel
                                    key="Input-Observatii"
                                    label="Observatii"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="text"
                                        name="Observatii"
                                        value={values.Observatii}
                                        onChange={handleChange}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col md>
                                <FloatingLabel
                                    key="Input-ObservatiiProgramare"
                                    label="Observatii programare"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="text"
                                        name="ObservatiiProgramare"
                                        value={values.ObservatiiProgramare}
                                        onChange={handleChange}
                                    />
                                    <Form.Control.Feedback className="d-block" type="invalid">
                                        {errors.ObservatiiProgramare}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <FloatingLabel key="Input-Pret" label="Pret" className="mb-3">
                            <Form.Control
                                type="text"
                                name="Pret"
                                value={getPriceValue()}
                                readOnly
                            />
                        </FloatingLabel>
                        <FloatingLabel key="Input-Oras" label="Oras" className="mb-3">
                            <Form.Select
                                name="OrasSelect"
                                value={values.OrasSelect}
                                onChange={handleChange}
                                isValid={touched.OrasSelect && !errors.OrasSelect}
                                isInvalid={!!errors.OrasSelect}
                            >
                                <option value="invalid">Selectează un oraș</option>
                                {
                                    Object.keys(CITIES).map((key) => {
                                        return <option value={key}>{CITIES[key]}</option>;
                                    })
                                }
                            </Form.Select>
                            <Form.Control.Feedback className="d-block" type="invalid">
                                {errors.OrasSelect}
                            </Form.Control.Feedback>
                        </FloatingLabel>
                        <Row className="g-2">
                            <Col xs={12} md={8}>
                                <FloatingLabel
                                    key="Input-Strada"
                                    label="Strada"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="text"
                                        name="Strada"
                                        value={values.Strada}
                                        onChange={handleChange}
                                    />
                                    <Form.Control.Feedback className="d-block" type="invalid">
                                        {errors.Strada}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>

                            <Col xs={6} md={4}>
                                <FloatingLabel
                                    key="Input-Numarul"
                                    label="Numarul"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="text"
                                        name="Numarul"
                                        value={values.Numarul}
                                        onChange={handleChange}
                                    />
                                    <Form.Control.Feedback className="d-block" type="invalid">
                                        {errors.Numarul}
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <FloatingLabel key="Input-Telefon" label="Telefon" className="mb-3">
                            <Form.Control
                                type="text"
                                name="Telefon"
                                value={values.Telefon}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback className="d-block" type="invalid">
                                {errors.Telefon}
                            </Form.Control.Feedback>
                        </FloatingLabel>

                        <div className="form-control padding-terms mb-3">
                            <Form.Check
                                type="checkbox"
                                id="terms-checkbox"
                                label="Accept termenii si conditiile"
                                name="Terms"
                                value={values.Terms}
                                onChange={handleChange}
                                isValid={touched.Terms && !errors.Terms}
                                isInvalid={touched.Terms && !!errors.Terms}
                            />
                            <Form.Control.Feedback
                                className="d-block"
                                onClick={() => {
                                    setShowTermsModal(true);
                                }}
                            >
                                <FontAwesomeIcon icon={faBookOpen}/>
                            </Form.Control.Feedback>
                            <Form.Control.Feedback className="d-block" type="invalid">
                                {errors.Terms}
                            </Form.Control.Feedback>
                        </div>
                        <Button type="submit" disabled={error !== ""}>
                            {isSubmitting ? "Se incarca..." : "Plateste"}
                        </Button>
                        {error && <Alert variant="danger">{error}</Alert>}
                    </Form>
                )}
            </Formik>

            <TermsModal
                show={showTermsModal}
                handleClose={() => handleClose_TermsModal()}
            />
        </>
    );
}
