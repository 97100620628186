import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import Contact from "../../Components/Contact/Contact";
import Faq from "../../Components/Faq/faq";
import Services from "../../Components/Services/Services";
import Footer from "../../Components/Footer/Footer";
import PresentationPlatform from "../../Components/Presentation/PresentationPlatform";
import PresentationExpert from "../../Components/Presentation/PresentationExpert";
import "../../styles.css";
import { useLocation } from "react-router-dom";
import CookieBanner from "../../Components/CookieBanner/CookieBanner";

function Main() {
  const location = useLocation();

  useEffect(() => {
    console.log("location=", location);
    const sectionId = location.hash.replace("#", "");
    const element = document.getElementById(sectionId);
    if (element !== null) {
      element.scrollIntoView();
    }
    console.log("element=", element);
  }, []);

  function Welcome() {
    return (
      <section className="main-wrapper " id="welcome">
        <div>
          <div className="main-heading text-transform">
            Sănătate, împlinire, acasă
          </div>
          <Button
            className="btn btn-primary btn-xl text-uppercase"
            href="#presentation"
          >
            Afla mai multe
          </Button>
        </div>
          <CookieBanner />
      </section>
    );
  }

  return (
    <div>
      <Welcome />
      <PresentationPlatform />
      <Services userPerspective={false} />
      <Faq />
      <PresentationExpert />
      <Contact />
      <Footer />
    </div>
  );
}

export default Main;
