import "../../styles.css";
import React from "react";
import { useParams } from "react-router-dom";
import AllPagesPDFViewer from "./AllPages";

function DocViewerWrapper(props) {
  const { DocumentName } = useParams();

  let pdf;

  try {
    if (props.DocumentName) {
      pdf = require(`../../assets/documents/${props.DocumentName}`).default;
    } else {
      pdf = require(`../../assets/documents/${DocumentName}`).default;
    }
  } catch (e) {}

  return (
    <section id="docviewer" className="">
      {pdf ? (
        <>
          <div className="">
            <AllPagesPDFViewer pdf={pdf} />
          </div>
        </>
      ) : (
        "Momentan experimentam niste dificultati in a afisa acest continut. Pentru detalii contactati-ne la adresa de email contact@volli.ro"
      )}
    </section>
  );
}

export default DocViewerWrapper;
