import React from "react";
import { db } from "../../Auth/firebase";
import {
  doc,
  updateDoc,
  setDoc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import * as global from "../../Helpers/globalConsts";

const ok = 100;
const okReassign = 110;
const notOk = 200;
const obsNeeded = 300;

export function getDateTime() {
  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;
  return dateTime;
}

function ValidateStatusChange(statusToBeSet, currentStatus) {
  if (
    (currentStatus === global.Asignat || currentStatus === global.Reasignat) &&
    statusToBeSet === global.InCurs
  ) {
    return ok;
  } else if (
    currentStatus === global.InCurs &&
    statusToBeSet === global.Rejectat
  ) {
    return obsNeeded;
  } else if (
    currentStatus === global.InCurs &&
    statusToBeSet === global.Solutionat
  ) {
    return obsNeeded;
  } else return notOk;
}

export async function UpdateStatusField(
  statusToBeSet,
  currentStatus,
  obsToBeSet,
  id
) {
  console.log('id', id);
  console.log("UpdateObsMedicField ", obsToBeSet);
  switch (ValidateStatusChange(statusToBeSet, currentStatus)) {
    case ok:
      const updateStatus = async () => {
        await updateDoc(
          doc(db, "OnGoingOrders", id),
          {
            Status: statusToBeSet,
          },
          { merge: true }
        );
      };

      try {
        updateStatus();
        return true;
      } catch (error) {
        return false;
      }
    case notOk:
      break;
    case obsNeeded:
      const updateStatusAndObs = async () => {
        await setDoc(
          doc(db, "OnGoingOrders", id),
          {
            ObservatiiMedic: obsToBeSet,
            Status: statusToBeSet,
            DataSetareStatusFinal: getDateTime(),
          },
          { merge: true }
        );
      };

      try {
        if (typeof obsToBeSet !== "undefined" && obsToBeSet.length > 14) {
          await updateStatusAndObs();
          return true;
        } else {
          alert(
            "Observatiile nu pot fi lasate necompletate. Cel putin 15 caractere trebuie sa existe in campul observatii!"
          );
          return false;
        }
      } catch (error) {
        alert("Eroare la interactiunea cu baza de date! Eroare: ", error);
        return false;
      }
  }
}

export async function assignAndMove(data, medicAsignat, asignatBy) {
  const id = data.id;
  delete data.id;

  const docSnap = await getDoc(doc(db, "OnGoingOrders", id));
  if (docSnap.exists()) {
    alert(
      "Exista deja un request cu acest id! Contacteaza echipa IT pentru a rezolva situatia!"
    );
  } else {
    data.DataAsignarii = getDateTime();

    data.MedicAsignat = medicAsignat;
    data.Asignator = asignatBy;
    data.Status = global.Asignat;

    console.log("AssignAndMove", data);

    await setDoc(doc(db, "OnGoingOrders", id), data);
    console.log("Asignare facuta cu succes!");
    await deleteDoc(doc(db, "ConfirmedOrders", id));
    console.log("Stergere facuta cu succes!");
  }
}

async function moveDocumentToHistoryCollection(data) {
  const id = data.id;
  delete data.id;

  const docSnap = await getDoc(doc(db, "history", id));
  if (docSnap.exists()) {
    alert(
      "Exista deja un request cu acest id in colectia history! Contacteaza echipa IT pentru a rezolva situatia!"
    );
  } else {
    data.DataFinalizarii = getDateTime();

    console.log("Data finalizarii: ", data);
    await setDoc(doc(db, "history", id), data);
    console.log("Asignare facuta cu succes!");
    await deleteDoc(doc(db, "OnGoingOrders", id));
    console.log("Stergere facuta cu succes!");
  }
}

function ValidateFinalise(statusToBeSet, currentStatus) {
  if (
    (currentStatus === global.Rejectat ||
      currentStatus === global.Solutionat) &&
    statusToBeSet === global.Reasignat
  ) {
    return okReassign;
  } else if (
    currentStatus === global.Solutionat &&
    statusToBeSet === global.Solutionat
  ) {
    return ok;
  } else if (
    currentStatus === global.Rejectat &&
    statusToBeSet === global.Rejectat
  ) {
    return ok;
  } else return notOk;
}

export async function FinaliseRequest(statusToBeSet, currentStatus, id, data) {
  switch (ValidateFinalise(statusToBeSet, currentStatus)) {
    case okReassign:
      const updateStatus = async () => {
        await updateDoc(
          doc(db, "OnGoingOrders", id),
          {
            Status: statusToBeSet,
          },
          { merge: true }
        );
      };

      try {
        updateStatus();
        return true;
      } catch (error) {
        return false;
      }

    case ok:
      moveDocumentToHistoryCollection(data);
      return true;

    case notOk:
      alert(
        "Nu se poate face transferul in aceasta stare! (Rejectat <-> Solutionat)"
      );
      return false;
  }
}
