import React, {useEffect, useState} from "react";
import "../../styles.css";
import {useAuth} from "../../Contexts/AuthContext";
import {db} from "../../Auth/firebase";
import {collection, query, where, onSnapshot} from "firebase/firestore";
import {TabelStore} from "../TableStore/tableStore";
import {Button} from "react-bootstrap";
import * as global from "../../Helpers/globalConsts";

export default function TableManager() {
    const [confirmedOrders, setConfirmedOrders] = useState([]);
    const [medicUsers, setMedicUsers] = useState([]);
    const [pendingRequests, setPendingRequests] = useState([]);
    const [onGoingRequests, setOnGoingRequests] = useState([]);

    const [showAdminTable, setShowAdminTable] = useState(false);
    const [showMedicTable, setShowMedicTable] = useState(false);
    const [showOnGoingRequestTable, setShowOnGoingRequestTable] = useState(false);

    const {currentUser, adminRights, medicRights} = useAuth();

    useEffect(() => {
        console.log("Admin este :" + adminRights);

        console.log("Medic este :" + medicRights);
    }, [adminRights, medicRights]);

    useEffect(() => {
        onSnapshot(collection(db, "ConfirmedOrders"), (snapshot) => {
                const dataSet = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
                setConfirmedOrders(
                    dataSet.filter((doc) => doc.Oras === adminRights.area)
                )
            }
        );
    }, []);

    useEffect(() => {
        onSnapshot(collection(db, "OnGoingOrders"), (snapshot) => {
                const dataSet = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
                setOnGoingRequests(
                    dataSet.filter((doc) => doc.Oras === adminRights.area)
                )
            }
        );
    }, []);

    useEffect(() => {
        const q = query(
            collection(db, "OnGoingOrders"),
            where("Status", "in", [global.Asignat, global.Reasignat, global.InCurs])
        );


        onSnapshot(q, (snapshot) => {
            setPendingRequests(
                snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
            );
        });
    }, []);

    useEffect(() => {
        console.log("Service Requests", confirmedOrders);

        console.log("Pending Requests", pendingRequests);
    }, [confirmedOrders, pendingRequests]);

    useEffect(() => {
        onSnapshot(collection(db, "medicUsers"), (snapshot) => {
                const medicData = snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}));
                const doctorsForSpecificArea = medicData.filter((doctor) => doctor.area === adminRights.area);
                setMedicUsers(doctorsForSpecificArea);
            }
        );
    }, []);

    useEffect(() => {
        console.log("Medics", medicUsers);
    }, [medicUsers]);

    return (
        <>
            <section id="dashboard1" className="main-wrapper dashboard-fixed">
                <div className="dashboardContainer">
                    <div className="switch-buttons">
                        <Button
                            className={`tabel-button ${
                                showAdminTable ? "tabel-button-active" : ""
                            }`}
                            disabled={!adminRights.hasRights}
                            onClick={() => {
                                setShowAdminTable(true);
                                setShowOnGoingRequestTable(false);
                                setShowMedicTable(false);
                            }}
                        >
                            {showAdminTable && (
                                <i className="fas fa-thumbtack black-icon"></i>
                            )}
                            SWITCH TO ADMIN DASHBOARD
                        </Button>

                        <Button
                            className={`tabel-button ${
                                showOnGoingRequestTable ? "tabel-button-active" : ""
                            }`}
                            disabled={!adminRights.hasRights}
                            onClick={() => {
                                setShowAdminTable(false);
                                setShowOnGoingRequestTable(true);
                                setShowMedicTable(false);
                            }}
                        >
                            {showOnGoingRequestTable && (
                                <i className="fas fa-thumbtack black-icon"></i>
                            )}
                            SWITCH TO ONGOINGREQUEST DASHBOARD
                        </Button>

                        <Button
                            className={`tabel-button ${
                                showMedicTable ? "tabel-button-active" : ""
                            }`}
                            disabled={!medicRights.hasRights}
                            onClick={() => {
                                setShowAdminTable(false);
                                setShowOnGoingRequestTable(false);
                                setShowMedicTable(true);
                            }}
                        >
                            {showMedicTable && (
                                <i className="fas fa-thumbtack black-icon"></i>
                            )}
                            SWITCH TO MEDIC DASHBOARD
                        </Button>
                    </div>

                    {showAdminTable && (
                        <TabelStore
                            content={confirmedOrders}
                            medicUsers={medicUsers}
                            table={"serviceRequest"}
                        />
                    )}
                    {showOnGoingRequestTable && (
                        <TabelStore
                            content={onGoingRequests}
                            medicUsers={medicUsers}
                            table={"onGoingRequest"}
                        />
                    )}
                    {showMedicTable && (
                        <TabelStore
                            content={pendingRequests}
                            table={"pendingRequest"}
                        />
                    )}
                </div>
            </section>
        </>
    );
}
