import React, { useState } from "react";
import { Formik } from "formik";
import { Form, FloatingLabel, Button, Alert } from "react-bootstrap";
import * as yup from "yup";
import { useAuth } from "../../Contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");

  const handleSubmit = async (values) => {
    try {
      setError("");
      setIsLoading(true);
      await login(values.Email, values.Parola);
    } catch (e) {
      setError("Autentificarea nu a reusit");
      console.log("Error" + e);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  return (
    <>
      <section className="main-wrapper">
        <div className="emailAndPassContainer">
        <div className="item-title ext-uppercase">
            Login
          </div>
          <Formik
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            initialValues={{
              Email: "",
              Parola: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              isSubmitting,
            }) => (
              <Form
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  console.log("submit");
                  console.log(values);
                  console.log(errors);
                  setValidateAfterSubmit(true);
                  handleSubmit();
                }}
              >
                <FloatingLabel key="Input-Email" label="Email" className="mb-3">
                  <Form.Control
                    type="text"
                    name="Email"
                    value={values.Email}
                    onChange={handleChange}
                    isValid={touched.Email && !errors.Email}
                    isInvalid={!!errors.Email}
                  />
                </FloatingLabel>

                <FloatingLabel
                  key="Input-Parola"
                  label="Parola"
                  className="mb-3"
                >
                  <Form.Control
                    type="password"
                    name="Parola"
                    value={values.Parola}
                    onChange={handleChange}
                    isValid={touched.Parola && !errors.Parola}
                    isInvalid={!!errors.Parola}
                  />
                </FloatingLabel>
                <div className="small forgotPass mb-3 text-dark">
                  Ti-ai uitat parola?{" "}
                  <Link to="/forgot-password">Reseteaza aici</Link>
                </div>
                <Button type="submit">
                  {isLoading ? "Se incarca..." : "Logheaza-te"}
                </Button>

                <div className="sign-up">
                  Nu ai un cont? <Link to="/sign-up">Inregistreaza-te</Link>
                </div>
              </Form>
            )}
          </Formik>
          {error && <Alert variant="danger">{error}</Alert>}
        </div>
      </section>
    </>
  );
}

export default Login;
