import "../../styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faUniversalAccess,
  faHandHoldingDollar,
  faPersonCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function PresentationExpert() {
  let navigate = useNavigate();
  return (
    <section className="section bg-light" id="specialist">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">
            Devino expert Volli
          </h2>
          <br />
        </div>
        <div className="row text-center">
          <div className="col-md-4">
            <span className="fa-stack fa-6x">
              <FontAwesomeIcon
                icon={faCircle}
                className="volli-yellow fa-stack-2x"
              />
              <FontAwesomeIcon
                icon={faUniversalAccess}
                className="fa-stack-1x fa-inverse"
              />
            </span>
            <h4 className="presentation-title">Flexibilitate</h4>
            <p className="presentation-description text-muted ">
              Propriul tău program în funcție de disponibilitatea ta. Mai mult
              timp liber ca să îl petreci alături de cei dragi ție.
            </p>
          </div>
          <div className="col-md-4">
            <span className="fa-stack fa-6x">
              <FontAwesomeIcon
                icon={faCircle}
                className="volli-yellow fa-stack-2x"
              />
              <FontAwesomeIcon
                icon={faHandHoldingDollar}
                className="fa-stack-1x fa-inverse"
              />
            </span>
            <h4 className="presentation-title">
              Venituri suplimentare și dezvoltare personală
            </h4>
            <p className="presentation-description text-muted">
              O platformă, mai multe surse de venit, inclusiv oportunitatea de a
              câștiga venit pasiv. Volli te va sprijini în crearea și
              dezvoltarea propriului Brand.
            </p>
          </div>
          <div className="col-md-4">
            <span className="fa-stack fa-6x">
              <FontAwesomeIcon
                icon={faCircle}
                className="volli-yellow fa-stack-2x"
              />
              <FontAwesomeIcon
                icon={faPersonCirclePlus}
                className="fa-stack-1x fa-inverse"
              />
            </span>
            <h4 className="presentation-title">
              Plus de valoare la tine acasă
            </h4>
            <p className="presentation-description text-muted">
              Dorești să aduci serviciile Volli în comunitatea ta ? Dacă
              activezi în orice domeniu al sănătății sau deții un cabinet
              medical acreditat vizitelor la domiciliu, contactează-ne chiar
              acum !
            </p>
          </div>
        </div>

        <Button
          className="btn btn-primary btn-xl text-uppercase"
          onClick={() => {
            navigate("/sign-up/specialist");
          }}
        >
          Aplica acum sa devii EXPERT volli
        </Button>
      </div>
    </section>
  );
}

export default PresentationExpert;
