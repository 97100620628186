import { useEffect, useState } from "react";
import { Button, Carousel } from "react-bootstrap";
import slide_0 from "../../assets/images/carousel/slide_0.jpeg";
import "../../styles.css";

function PresentationPlatform() {
  const [index, setIndex] = useState(0);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function removeActiveStyle(id) {
    document.getElementById(`carousel_text_${id}`).classList.add("greyed-text");
    document
      .getElementById(`carousel_text_${id}`)
      .classList.remove("active-text");

    document
      .getElementById(`carousel_header_${id}`)
      .classList.add("greyed-text");
    document
      .getElementById(`carousel_header_${id}`)
      .classList.remove("active-text");

    document
      .getElementById(`carousel_header_no_${id}`)
      .classList.add("greyed-text");
    document
      .getElementById(`carousel_header_no_${id}`)
      .classList.remove("volli-yellow");
  }

  function addActiveStyle(id) {
    document
      .getElementById(`carousel_text_${id}`)
      .classList.remove("greyed-text");
    document.getElementById(`carousel_text_${id}`).classList.add("active-text");

    document
      .getElementById(`carousel_header_${id}`)
      .classList.remove("greyed-text");
    document
      .getElementById(`carousel_header_${id}`)
      .classList.add("active-text");

    document
      .getElementById(`carousel_header_no_${id}`)
      .classList.remove("greyed-text");
    document
      .getElementById(`carousel_header_no_${id}`)
      .classList.add("volli-yellow");
  }

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 1000) {
      console.log("mobile screen true");
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }
  }, [windowWidth]);

  let captionTexts = {
    0: {
      header: "Iti creezi profil",
      paragraph: "Urmează pașii din platforma și creează-ți un cont.",
    },
    1: {
      header: "Selecteaza un serviciu",
      paragraph: "Alege serviciul de care ai nevoie",
    },
    2: {
      header: "Flexibilitate",
      paragraph:
        "Un administrator volli va lua telefonic legatura cu tine sa stabiliti cel mai optim interval orar",
    },
    3: {
      header: "Profita de timpul TAU",
      paragraph:
        "Câștigă timp pentru tine și cei dragi. Renunță la orele pierdute în sălile de așteptare",
    },
  };

  useEffect(() => {
    try {
      removeActiveStyle(0);
      removeActiveStyle(1);
      removeActiveStyle(2);
      removeActiveStyle(3);

      addActiveStyle(index);
    } catch (error) {
    }
  }, [index]);

  return (
    <section className="section carousel" id="presentation">
      <div
        className={
          !isMobileScreen ? "carousel-container" : "carousel-mobile-container"
        }
      >
        {!isMobileScreen && (
          <>
            <div className='carousel-text'>
              <h2 className='header'>
                Cum funcționează <span className='volli-yellow'>Volli</span>?
              </h2>
              <Button
                id='carousel_text_0'
                variant='secondary'
                className='greyed-text w-100 step-btn'
                onClick={() => setIndex(0)}
              >
                <span className='step-header-wrapper'>
                  <h4 id='carousel_header_no_0' className="number">1</h4>{" "}
                  <Button
                    id='carousel_header_0'
                    variant='link'
                    className='greyed-text'
                  >
                    <h4>{captionTexts[0].header}</h4>
                    <p>{captionTexts[0].paragraph}</p>
                  </Button>
                </span>
              </Button>
              <Button
                id='carousel_text_1'
                variant='secondary'
                className='greyed-text w-100 step-btn'
                onClick={() => setIndex(1)}
              >
                <span className='step-header-wrapper'>
                  <h4 id='carousel_header_no_1' className="number">2</h4>{" "}
                  <Button
                    id='carousel_header_1'
                    variant='link'
                    className='greyed-text'
                  >
                    <h4>{captionTexts[1].header}</h4>
                    <p>{captionTexts[1].paragraph}</p>
                  </Button>
                </span>
              </Button>
              <Button
                id='carousel_text_2'
                variant='secondary'
                className='greyed-text w-100 step-btn'
                onClick={() => setIndex(2)}
              >
                <span className='step-header-wrapper'>
                  <h4 id='carousel_header_no_2' className="number">3</h4>{" "}
                  <Button
                    id='carousel_header_2'
                    variant='link'
                    className='greyed-text'
                  >
                    <h4>{captionTexts[2].header}</h4>
                    <p>{captionTexts[2].paragraph}</p>
                  </Button>
                </span>
              </Button>
              <Button
                id='carousel_text_3'
                variant='secondary'
                className='greyed-text step-btn'
                onClick={() => setIndex(3)}
              >
                <span className='step-header-wrapper'>
                  <h4 id='carousel_header_no_3' className="number">4</h4>{" "}
                  <Button
                    id='carousel_header_3'
                    variant='link'
                    className='greyed-text w-100'
                  >
                    <h4>{captionTexts[3].header}</h4>
                    <p>{captionTexts[3].paragraph}</p>
                  </Button>
                </span>
              </Button>
            </div>
          </>
        )}
        <>
          {isMobileScreen && (
            <h4>
              Cum funcționează <span className='volli-yellow'>Volli</span>?
            </h4>
          )}
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            className={
              !isMobileScreen ? "carousel-general" : "carousel-mobile-general"
            }
            variant="dark"
          >
            <Carousel.Item id='slide_1'>
              <img className='carousel-img' src={slide_0} alt='First slide' />
              {isMobileScreen && (
                <Carousel.Caption>
                  <h5>{captionTexts[0].header}</h5>
                  <p>{captionTexts[0].paragraph}</p>
                </Carousel.Caption>
              )}
            </Carousel.Item>
            <Carousel.Item id='slide_2'>
              <img className='carousel-img' src={slide_0} alt='Second slide' />
              {isMobileScreen && (
                <Carousel.Caption>
                  <h5>{captionTexts[1].header}</h5>
                  <p>{captionTexts[1].paragraph}</p>
                </Carousel.Caption>
              )}
            </Carousel.Item>
            <Carousel.Item id='slide_3'>
              <img className='carousel-img' src={slide_0} alt='Third slide' />
              {isMobileScreen && (
                <Carousel.Caption>
                  <h5>{captionTexts[2].header}</h5>
                  <p>{captionTexts[2].paragraph}</p>
                </Carousel.Caption>
              )}
            </Carousel.Item>
            <Carousel.Item id='slide_4'>
              <img className='carousel-img' src={slide_0} alt='Fourth slide' />
              {isMobileScreen && (
                <Carousel.Caption>
                  <h5>{captionTexts[3].header}</h5>
                  <p>{captionTexts[3].paragraph}</p>
                </Carousel.Caption>
              )}
            </Carousel.Item>
          </Carousel>
        </>
      </div>
    </section>
  );
}

export default PresentationPlatform;
