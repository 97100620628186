import React, { useEffect, useState } from "react";
import "../../styles.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthContext";
import { BootstrapUserTable } from "../../Components/TableStore/bootstrapUserTable";
import { Button } from "react-bootstrap";
import { db } from "../../Auth/firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";

export default function History() {
  const { currentUser, adminRights, medicRights} = useAuth();
  const [historyRequest, setHistoryRequest] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    const q1 = query(
      collection(db, "history"),
      where("Email", "==", `${currentUser.email}`)
    );
    onSnapshot(q1, (snapshot) => {
      setHistoryRequest(
        snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    });

    const q2 = query(
      collection(db, "OnGoingOrders"),
      where("Email", "==", `${currentUser.email}`)
    );
    onSnapshot(q2, (snapshot) => {
      setHistoryRequest(x => [...x, ...snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))]);
    });

  }, []);

  useEffect(() => {
    console.log("History", historyRequest);
  }, [historyRequest]);

  return (
        <section className="main-wrapper">
          <div className="dashboardContainer">

          {(adminRights.hasRights || medicRights.hasRights) && (
                <Button className="mb-3" onClick={() => navigate("/history/tableManager")}>
                  Go to history table manager
                </Button>
              )}
              <BootstrapUserTable content={historyRequest} table="historyRequest" />
          </div>
        </section>
  );
}
