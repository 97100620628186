import React, { useState, useRef } from "react";
import "../../styles.css";
import "./NavBarStyles.css";
import { Navbar, Nav, Container } from "react-bootstrap";
import { useAuth } from "../../Contexts/AuthContext";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/logo_volli.png";

import "bootstrap/dist/css/bootstrap.min.css";

export function MakeLoginLinkActive() {
  React.useEffect(() => {
    var loginElement = document.getElementById("loginNavBar");
    if (loginElement !== null) {
      loginElement.classList.add("active");
    }
  }, []);
}

function NavBar() {
  const [error, setError] = useState("");
  const [showUserData, setShowUserData] = useState(false);
  const { currentUser, logout } = useAuth();
  const [noScroll, setNoScroll] = useState(true);
  const [isScrolling, setIsScrolling] = useState(false);

  const [togglerIsOn, setTogglerIsOn] = useState(false);

  const [navBarGrey, setNavBarGrey] = useState(false);
  const location = useLocation();

  async function handleLogout() {
    setError("");

    try {
      await logout();
    } catch {
      setError("Failed to log out");
    }
  }

  React.useEffect(() => {
    if (location.pathname.indexOf("/dashboard") !== -1) setShowUserData(true);
  }, [location]);

  React.useEffect(() => {
    console.log("SSSSS");
    window.addEventListener("scroll", (event) => {
      setNoScroll(false);
      setIsScrolling(true);
      const navBar = document.getElementById("mainNav");
      if (!navBar) {
        return;
      }

      if (window.scrollY === 0) {
        setNoScroll(true);
      } else setNoScroll(false);

      const sections = document.querySelectorAll("section");
      const navLi = document.querySelectorAll("nav div div a");

      let current = "";
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;

        if (window.scrollY >= sectionTop - sectionHeight / 3) {
          current = section.getAttribute("id");
        }

        navLi.forEach((a) => {
          a.classList.remove("active");
          if (a.classList.contains(current)) {
            a.classList.add("active");
          }
        });
      });
      setIsScrolling(false);
    });
  }, []);

  React.useEffect(() => {
    if (noScroll === false) {
      setNavBarGrey(true);
    }
    if (isScrolling === true && togglerIsOn === true) {
      setTogglerIsOn(false);
    }
    if (noScroll && togglerIsOn === false) {
      setNavBarGrey(false);
    }
    if (noScroll && togglerIsOn === true) {
      setNavBarGrey(true);
    }
  }, [togglerIsOn, noScroll, isScrolling]);

  return (
    <div>
      <Navbar
        className={`navBar fixed-top ${navBarGrey && "navBar-grey"} `}
        id='mainNav'
        collapseOnSelect
        expand='lg'
        expanded={togglerIsOn}
      >
        <Navbar.Brand href='/'>
          <img
            alt=''
            src={logo}
            width='70'
            height='70'
            className='d-inline-block align-top'
          />{" "}
          {/* Volli */}
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={() => {
            setTogglerIsOn((prevTogglerIsOn) => !prevTogglerIsOn);
          }}
          aria-controls='responsive-navbar-nav'
        />
        <Navbar.Collapse id='responsive-navbar-nav' className='navBar-links'>
          <Nav>
            {!showUserData ? (
              <>
                <Nav.Link
                  href='/#presentation'
                  className='navLink text-uppercase presentation'
                >
                  Ce gasesc aici?
                </Nav.Link>
                <Nav.Link
                  href='/#services'
                  className='navLink text-uppercase services'
                >
                  Servicii
                </Nav.Link>
                <Nav.Link href='/#faq' className='navLink text-uppercase faq'>
                  Intrebari frecvente
                </Nav.Link>
                <Nav.Link
                  id='signUpSpecialistNavBar'
                  href='/#specialist'
                  className='navLink text-uppercase specialist'
                >
                  Devino Expert Volli
                </Nav.Link>
                <Nav.Link
                  href='/#contact'
                  className='navLink text-uppercase contact'
                >
                  Contact
                </Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link
                  href='/dashboard/services'
                  className='navLink text-uppercase'
                >
                  Servicii
                </Nav.Link>
                {/*<Nav.Link*/}
                {/*  href='/dashboard/history'*/}
                {/*  className='navLink text-uppercase'*/}
                {/*>*/}
                {/*  Istoric*/}
                {/*</Nav.Link>*/}
              </>
            )}
            {currentUser ? (
              <>
                <Nav.Link
                  href='/dashboard'
                  className='navLink text-uppercase userNav'
                >
                  {currentUser.email}
                </Nav.Link>
                <Nav.Link
                  id='logOutNavBar'
                  href='/'
                  className='navLink text-uppercase'
                  onClick={handleLogout}
                >
                  Logout
                </Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link
                  id='loginNavBar'
                  href='/login'
                  className='navLink text-uppercase'
                >
                  Login
                </Nav.Link>
                <Nav.Link
                  id='signUpNavBar'
                  href='/sign-up'
                  className='navLink text-uppercase'
                >
                  Sign-up
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default NavBar;
